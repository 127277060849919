import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./styles.css";

import CartProduct from "../../../components/CartProduct/CartProduct";

import api from "../../../services/api";

import clientIsAuthenticated from "../../../auth/clientIsAuthenticated";

import calcCartTotalValue from "../../../functions/calcCartTotalValue";

import Sidebar from "../ClientSidebar";

import coins_img from "../../../assets/coins_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientCart() {
  const pageTitle = "cart";

  const [orderProducts, setOrderProducts] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const history = useHistory();

  function callback(total) {
    setTotalValue(total);
  }

  async function updateProductPrices() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get("produtos", options);
      // console.log(response.data);

      if (response.data !== "") {
        const cartString = localStorage.getItem("cart");
        const cart = JSON.parse(cartString);

        cart.map((cartProduct, ind) => {
          let productFound = false;

          response.data.map((product) => {
            // console.log(`${product.id} = ${cartProduct.id}`);
            // console.log(product.preco);

            if (Number(product.id) === Number(cartProduct.id)) {
              productFound = true;
              cartProduct.price = Number(product.preco) / 100;
              // console.log(cartProduct.price);
            }
          });

          if (!productFound) cart.splice(ind, 1);
        });

        localStorage.setItem("cart", JSON.stringify(cart));

        updateCartProducts();
      } else {
        localStorage.removeItem("cart");
        localStorage.removeItem("cartTotalValue");
      }
    } catch (err) {
      // console.log(err);
      // handleErrorType(err.response.status);
    }
  }

  function handleFinalizeOrder(e) {
    e.preventDefault();

    if (clientIsAuthenticated()) {
      history.push("/client/finalize-order");
    } else {
      history.push("/client/login");
    }
  }

  function updateCartProducts() {
    const cartString = localStorage.getItem("cart");

    if (cartString !== null) {
      const cart = JSON.parse(cartString).filter(
        (product) => product.quantity !== 0
      );

      // console.log(cart);
      if (cart.length > 0) {
        setOrderProducts(cart);
        localStorage.setItem("cart", JSON.stringify(cart));
      } else {
        localStorage.removeItem("cart");
        localStorage.removeItem("cartTotalValue");
      }
    }
  }

  useEffect(() => {
    // console.log(localStorage.getItem("cart"));
    // console.log(JSON.parse(localStorage.getItem("cart")));

    updateProductPrices();
    // updateCartProducts();

    calcCartTotalValue();
    setTotalValue(Number(localStorage.getItem("cartTotalValue")));
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Meu carrinho</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        {orderProducts.length > 0 ? (
          <div className="cart_list">
            {/* {orders.map((order) => (
            <div className="order" to="/client/orders/data">
              <div className="order_products">
                <div className="order_products_store">
                  <div className="order_products_store_title">
                    <BiStore />
                    <h4>Sabor Perfeito</h4>
                  </div>
                </div>
                {orderProducts.map((product) => (
                  <div className="product" style={{ width: "100%" }}>
                    <div className="product_quant">
                      <button className="product_quant_button">
                        <BiMinus />
                      </button>
                      <input type="text" />
                      <button className="product_quant_button">
                        <BiPlus />
                      </button>
                    </div>
                    <div className="product_img_data">
                      <div className="product_img">
                        <img src={quiabo_img} alt="" />
                      </div>
                      <div className="product_data">
                        <h4>Quiabo em conserva</h4>
                        <h3>R$12,99</h3>
                      </div>
                    </div>
                    <div className="order_products_store_title">
                      <BiStore />
                      <h4>Sabor Perfeito</h4>
                    </div>
                  </div>
                ))}
              </div>
              <div className="order_data" style={{ border: "none" }}>
                <div className="order_value">
                  <div className="order_value_title">
                    <div className="order_value_title_img">
                      <img src={coins_img} alt="" />
                    </div>
                    <h4 className="order_total_value_title">Total</h4>
                  </div>
                  <h3 className="order_total_value">R$1499,90</h3>
                </div>
              </div>
            </div>
          ))} */}
            <div className="order">
              <div className="order_products">
                {orderProducts.map((product) => (
                  <CartProduct
                    product={product}
                    parentCallback={callback}
                    key={product.id}
                  />
                ))}
              </div>
              <div
                className="order_data"
                style={{ border: "none", alignItems: "flex-start" }}
              >
                <div className="order_value">
                  <div className="order_value_title">
                    <div className="order_value_title_img">
                      <img src={coins_img} alt="" />
                    </div>
                    <h4 className="order_total_value_title">Total</h4>
                  </div>
                  <h3 className="order_total_value">
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(totalValue)}
                  </h3>
                  <button className="button1" onClick={handleFinalizeOrder}>
                    Finalizar compra
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h3>Seu carrinho está vazio.</h3>
          </div>
        )}
        {/* <Link className="circle_button" to="">
          <BiCheck />
          <span className="caption">Finalizar compra</span>
        </Link> */}
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
