import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BiPencil,
  BiUser,
  BiIdCard,
  BiPhone,
  BiEnvelope,
  BiMap,
} from "react-icons/bi";

import "./styles.css";

import axios from "axios";
import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import applyCnpjMask from "../../../functions/applyCnpjMask";
import applyTelephoneMask from "../../../functions/applyTelephoneMask";
import applyCepMask from "../../../functions/applyCepMask";

import Sidebar from "../CollabSidebar";

import user_upload_img from "../../../assets/user_upload_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function CollabStoreData() {
  const pageTitle = "store";

  const [id, setId] = useState();

  const [fantasyName, setFantasyName] = useState("Nome-fantasia");
  const [cnpj, setCnpj] = useState("______________");
  const [telephone, setTelephone] = useState("___________");
  const [email, setEmail] = useState("Email");

  const [cep, setCep] = useState("_______");
  const [uf, setUf] = useState("UF");
  const [city, setCity] = useState("Cidade");
  const [district, setDistrict] = useState("Bairro");
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState("Rua");

  const [logoUrl, setLogoUrl] = useState(null);

  async function handleUploadImage(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      // console.log(reader.result);

      setLogoUrl(reader.result);
    });
    reader.readAsDataURL(file);

    const form = new FormData();
    form.append("logo", file);

    const options = {
      method: "POST",
      url: `https://api.vendarte.company/api/empreendimento/${id}/uploadphoto`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: form,
    };

    try {
      const response = await axios.request(options);
      // console.log(response.data);

      alert("Foto atualizada com sucesso!");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateStoreData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let id;

    try {
      const response = await api.get(
        `empreendedor/${localStorage.getItem("id")}`,
        options
      );
      // console.log(response.data);

      id = response.data.id_empreendimento;
      setId(response.data.id_empreendimento);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }

    try {
      const response = await api.get(`empreendimento/${id}`);
      // console.log(response.data);

      setFantasyName(response.data.nome);
      setCnpj(response.data.cnpj);
      setTelephone(response.data.celular);
      setEmail(response.data.email);

      setCep(response.data.endereco_cep);
      setUf(response.data.endereco_uf);
      setCity(response.data.endereco_cidade);
      setDistrict(response.data.endereco_bairro);
      setNumber(response.data.endereco_numero);
      setStreet(response.data.endereco_rua);

      if (response.data.logo_url !== null)
        setLogoUrl("https://api.vendarte.company" + response.data.logo_url);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateStoreData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Meu perfil</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="profile_details">
          <div className="profile_data_container">
            <div className="profile_data">
              <div className="title">
                <BiUser />
                <h2>Nome-fantasia</h2>
              </div>
              <p>{fantasyName}</p>
              <Link
                className="data_edit_icon_button"
                to="/collab/store/details/edit"
              >
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiIdCard />
                <h2>CNPJ</h2>
              </div>
              <p>{applyCnpjMask(cnpj)}</p>
              <Link
                className="data_edit_icon_button"
                to="/collab/store/details/edit"
              >
                {/* <BiPencil className="data_edit_icon" /> */}
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiPhone />
                <h2>Telefone</h2>
              </div>
              <p>{applyTelephoneMask(telephone)}</p>
              <Link
                className="data_edit_icon_button"
                to="/collab/store/details/edit"
              >
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiEnvelope />
                <h2>Email</h2>
              </div>
              <p>{email}</p>
              <Link
                className="data_edit_icon_button"
                to="/collab/store/details/edit"
              >
                {/* <BiPencil className="data_edit_icon" /> */}
              </Link>
            </div>
            <div className="profile_data" style={{ height: "100px" }}>
              <div className="title">
                <BiMap />
                <h2>Endereço</h2>
              </div>
              <p>
                {street}, nº {number}, {district}, {city}-{uf},{" "}
                {applyCepMask(cep)}
              </p>
              <Link
                className="data_edit_icon_button"
                to="/collab/store/details/edit"
              >
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
          </div>
          <div className="img_container">
            <img
              src={logoUrl !== null ? logoUrl : user_upload_img}
              alt="Foto de perfil"
            />
            <input type="file" id="img_add" onChange={handleUploadImage} />
            <label className="edit_icon_container" for="img_add">
              <BiPencil />
            </label>
          </div>
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
