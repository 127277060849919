import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { BiPencil, BiLock } from "react-icons/bi";

import "./styles.css";

import axios from "axios";
import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../AdminSidebar";

import user_upload_img from "../../../assets/user_upload_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function AdminProfileEdit() {
  const pageTitle = "profile";

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");

  const [photoUrl, setPhotoUrl] = useState(null);

  const history = useHistory();

  async function handleUploadImage(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      // console.log(reader.result);

      setPhotoUrl(reader.result);
    });
    reader.readAsDataURL(file);

    const form = new FormData();
    form.append("foto_url", file);

    const options = {
      method: "POST",
      url: `https://api.vendarte.company/api/administrador/uploadphoto/${localStorage.getItem(
        "id"
      )}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: form,
    };

    try {
      const response = await axios.request(options);
      // console.log(response.data);

      alert("Foto atualizada com sucesso!");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function handleEditAdminProfile(e) {
    e.preventDefault();

    const nome = name;
    // const cpf = cpf.replace(/[^\d]/g, "");
    // const email = email;
    const celular = telephone.replace(/[^\d]/g, "");

    const data = {
      nome,
      cpf: cpf.replace(/[^\d]/g, ""),
      email,
      celular,
    };

    // console.log(data);

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.put(
        `administrador/${localStorage.getItem("id")}`,
        data,
        options
      );
      alert("Informações atualizadas com sucesso!");
      // console.log(response.data);

      history.push("/admin/profile");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateProfileData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `administrador/${localStorage.getItem("id")}`,
        options
      );
      // console.log(response.data);

      setName(response.data.nome);
      setCpf(response.data.cpf);
      setTelephone(response.data.celular);
      setEmail(response.data.email);

      if (response.data.foto_url !== null)
        setPhotoUrl("https://api.vendarte.company" + response.data.foto_url);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateProfileData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Editar perfil</h1>

          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="profile_details">
          <div className="profile_data_container">
            <div className="text_input_container">
              <label className="text_input_title">Nome:</label>
              <input
                className="text_input_content"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome"
              />
            </div>
            <div className="text_input_container">
              <label className="text_input_title">CPF:</label>
              <InputMask
                className="text_input_content"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="CPF"
                mask="999.999.999-99"
                disabled="true"
              />
              <BiLock />
            </div>
            <div className="text_input_container">
              <label className="text_input_title">Telefone:</label>
              <InputMask
                className="text_input_content"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                placeholder="Telefone"
                mask="(99) 99999-9999"
              />
            </div>
            <div className="text_input_container">
              <label className="text_input_title">E-mail:</label>
              <input
                className="text_input_content"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                disabled="true"
              />
              <BiLock />
            </div>
            {/* <div className="text_input_line">
              <div
                className="text_input_container"
                style={{ minWidth: "295px" }}
              >
                <label className="text_input_title">CEP:</label>
                <input
                  className="text_input_content"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                  placeholder="CEP"
                />
              </div>
              <div
                className="text_input_container"
                style={{ width: "80px", minWidth: "80px", marginLeft: "25px" }}
              >
                <label className="text_input_title">UF:</label>
                <input
                  className="text_input_content"
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                  placeholder="UF"
                />
              </div>
            </div>
            <div className="text_input_container">
              <label className="text_input_title">Cidade:</label>
              <input
                className="text_input_content"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Cidade"
              />
            </div>
            <div className="text_input_line">
              <div
                className="text_input_container"
                style={{ minWidth: "285px" }}
              >
                <label className="text_input_title">Bairro:</label>
                <input
                  className="text_input_content"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  placeholder="Bairro"
                />
              </div>
              <div
                className="text_input_container"
                style={{ width: "20%", minWidth: "90px", marginLeft: "25px" }}
              >
                <label className="text_input_title">Número:</label>
                <input
                  className="text_input_content"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Número"
                />
              </div>
            </div>
            <div className="text_input_container">
              <label className="text_input_title">Rua:</label>
              <input
                className="text_input_content"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="Rua"
              />
            </div> */}
            <button className="button1" onClick={handleEditAdminProfile}>
              Finalizar edições
            </button>
          </div>
          <div className="img_container">
            <img
              src={photoUrl !== null ? photoUrl : user_upload_img}
              alt="Foto de perfil"
            />
            <input type="file" id="img_add" onChange={handleUploadImage} />
            <label className="edit_icon_container" for="img_add">
              <BiPencil />
            </label>
          </div>
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
