import React, { useState, useEffect } from "react";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../ClientSidebar";

import ClientOrder from "../../../components/ClientOrder/ClientOrder";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientOrders() {
  const pageTitle = "orders";

  const [orders, setOrders] = useState([]);

  // let currentOrderListTitle = "";

  // function changeCurrentOrderList(newOrderList) {
  //   let currentOrderList = document.querySelector("#" + currentOrderListTitle);
  //   currentOrderList.classList.toggle("active");

  //   currentOrderList = document.querySelector("#" + newOrderList);
  //   currentOrderList.classList.toggle("active");
  //   currentOrderListTitle = newOrderList;
  // }

  // function setCurrentOrderList() {
  //   try {
  //     let currentOrderList = document.querySelector("#processing");
  //     currentOrderList.classList.toggle("active");
  //     currentOrderListTitle = "processing";
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async function updateOrdersList() {
    const options = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(`cliente/pedido`, options);
      console.log(response.data);

      let aux = [];

      for (var key in response.data) {
        aux.push(response.data[key]);
      }

      setOrders(aux.sort((a, b) => (a.data_hora > b.data_hora ? -1 : 1)));
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    // setCurrentOrderList();
    updateOrdersList();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Meus pedidos</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        {/* <div className="top_bar">
          <button
            className="top_bar_button"
            id="processing"
            onClick={() => changeCurrentOrderList("processing")}
          >
            Em processamento
          </button>
          <button
            className="top_bar_button"
            id="transit"
            onClick={() => changeCurrentOrderList("transit")}
          >
            Em trânsito
          </button>
          <button
            className="top_bar_button"
            id="delivered"
            onClick={() => changeCurrentOrderList("delivered")}
          >
            Entregues
          </button>
        </div> */}
        <div className="orders_list">
          {orders.map((order) => (
            <ClientOrder order={order} key={order.id} />
          ))}
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
