export default function applyCpfMask(cpf) {
  if (cpf !== "" && cpf !== null && cpf !== undefined) {
    return "".concat(
      cpf.slice(0, 3),
      ".",
      cpf.slice(3, 6),
      ".",
      cpf.slice(6, 9),
      "-",
      cpf.slice(9)
    );
  } else {
    return cpf;
  }
}
