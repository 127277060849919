import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiStore } from "react-icons/bi";

import "./styles.css";

import api from "../../services/api";

import handleErrorType from "../../functions/handleErrorType";

import applyDateMask from "../../functions/applyDateMask";

import product_default_img from "../../assets/product_default_img.png";
import coins_img from "../../assets/coins_img.png";

export default function ClientOrder(order) {
  const [id, setId] = useState(order.order.id);
  const [storeId, setStoreId] = useState(order.order.id_empreendimento);
  const [date, setDate] = useState(order.order.data_hora);
  const [totalValue, setTotalValue] = useState(Number(order.order.total) / 100);

  const [storeName, setStoreName] = useState("Loja");

  const [orderProducts, setOrderProducts] = useState([]);

  async function updateStoreName() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(`empreendimento/${storeId}`, options);
      // console.log(response.data);

      setStoreName(response.data.nome);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateOrderData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(`cliente/pedido/${id}`, options);
      // console.log(response.data);

      setOrderProducts(response.data.produtos);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateOrderData();
    updateStoreName();
  }, []);

  return (
    <Link className="order" to={"/client/orders/details/" + id}>
      <div className="order_products">
        <p>{applyDateMask(date)}</p>
        {orderProducts.map((product) => (
          <div className="product" key={product.id}>
            <div className="product_img_data">
              <div className="product_img">
                <img
                  src={
                    "fotos_url" in product && product.fotos_url.length > 0
                      ? "https://api.vendarte.company" +
                        product.fotos_url[0].url_img
                      : product_default_img
                  }
                  alt="Foto do produto"
                />
              </div>
              <div className="product_data">
                <h4>{product.nome}</h4>
                <h3>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(Number(product.preco) / 100)}
                </h3>
              </div>
            </div>
            <div className="product_quant">x{product.pivot.quantidade}</div>
          </div>
        ))}
      </div>
      <div className="order_data">
        <div className="order_data_container">
          <div className="order_data_content">
            <div className="order_data_content_title">
              <BiStore />
              <h4>Loja</h4>
            </div>
            <p>{storeName}</p>
          </div>
          {/* <div className="order_data_content">
            <div className="order_data_content_title">
              <BiRename />
              <h4>Código de rastreio</h4>
            </div>
            <p>XYAH17261HYGSQYG</p>
          </div> */}
        </div>
        <div className="order_value">
          <div className="order_value_title">
            <div className="order_value_title_img">
              <img src={coins_img} alt="" />
            </div>
            <h4 className="order_total_value_title">Total</h4>
          </div>
          <h3 className="order_total_value">
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(totalValue)}
          </h3>
        </div>
      </div>
    </Link>
  );
}
