import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

import "./styles.css";

import Sidebar from "../CollabSidebar";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

import registerImg from "../../../assets/register_img.png";

export default function CollabCollabsRegisterRegister() {
  // const pageTitle = "";

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [collabTelephone, setCollabTelephone] = useState("");
  const [collabEmail, setCollabEmail] = useState("");
  const [collabPassword, setCollabPassword] = useState("");

  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState("");

  const history = useHistory();

  async function updateCep(cep) {
    setCep(cep);

    const options = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `validaCep/${cep.replace(/[^\d]/g, "")}`,
        options
      );
      // console.log(response.data);

      setCity(response.data.localidade);
      setUf(response.data.uf);
      setDistrict(response.data.bairro);
      setStreet(response.data.logradouro);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function handleRegisterCollab(e) {
    e.preventDefault();

    const nome = name;
    // const cpf = cpf;
    const email = collabEmail;
    const conf_email = collabEmail;
    const password = collabPassword;
    const conf_password = collabPassword;
    const celular = collabTelephone.replace(/[^\d]/g, "");

    const endereco_cep = cep.replace(/[^\d]/g, "");
    const endereco_uf = uf;
    const endereco_cidade = city;
    const endereco_bairro = district;
    const endereco_numero = number;
    const endereco_rua = street;

    const data = {
      nome,
      cpf: cpf.replace(/[^\d]/g, ""),
      email,
      conf_email,
      password,
      conf_password,
      celular,
      endereco_cep,
      endereco_rua,
      endereco_numero,
      endereco_cidade,
      endereco_uf,
      endereco_bairro,
    };

    // console.log(data);

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let id;

    try {
      const response = await api.get(
        `empreendedor/${localStorage.getItem("id")}`,
        options
      );
      // console.log(response.data);

      id = response.data.id_empreendimento;
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }

    try {
      const response = await api.post(
        `empreendimentos/${id}/colaborador`,
        data,
        options
      );
      // console.log(response.data);
      alert("Colaborador cadastrado com sucesso!");

      history.push("/collab/collabs");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Cadastrar um novo colaborador</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <form className="register_form">
          <div className="register_fields_container">
            <div className="register_fields">
              <div className="text_input_container">
                <label className="text_input_title">Nome:</label>
                <input
                  className="text_input_content"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nome"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ width: "50%", minWidth: "80px" }}
                >
                  <label className="text_input_title">CPF:</label>
                  <InputMask
                    className="text_input_content"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    placeholder="CPF"
                    mask="999.999.999-99"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{ width: "50%", minWidth: "80px", marginLeft: "25px" }}
                >
                  <label className="text_input_title">Telefone:</label>
                  <InputMask
                    className="text_input_content"
                    value={collabTelephone}
                    onChange={(e) => setCollabTelephone(e.target.value)}
                    placeholder="Telefone"
                    mask="(99) 99999-9999"
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">E-mail:</label>
                <input
                  className="text_input_content"
                  type="email"
                  value={collabEmail}
                  onChange={(e) => setCollabEmail(e.target.value)}
                  placeholder="E-mail"
                />
              </div>
              <div className="text_input_container">
                <label className="text_input_title">Senha:</label>
                <input
                  className="text_input_content"
                  type="password"
                  value={collabPassword}
                  onChange={(e) => setCollabPassword(e.target.value)}
                  placeholder="Senha"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ minWidth: "295px" }}
                >
                  <label className="text_input_title">CEP:</label>
                  <InputMask
                    className="text_input_content"
                    value={cep}
                    onChange={(e) => updateCep(e.target.value)}
                    placeholder="CEP"
                    mask="99.999-999"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{
                    width: "80px",
                    minWidth: "80px",
                    marginLeft: "25px",
                  }}
                >
                  <label className="text_input_title">UF:</label>
                  <input
                    className="text_input_content"
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    placeholder="UF"
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">Cidade:</label>
                <input
                  className="text_input_content"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Cidade"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ minWidth: "285px" }}
                >
                  <label className="text_input_title">Bairro:</label>
                  <input
                    className="text_input_content"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    placeholder="Bairro"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{
                    width: "20%",
                    minWidth: "90px",
                    marginLeft: "25px",
                  }}
                >
                  <label className="text_input_title">Número:</label>
                  <input
                    className="text_input_content"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Nº"
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">Rua:</label>
                <input
                  className="text_input_content"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  placeholder="Rua"
                />
              </div>
            </div>
            <button className="button1" onClick={handleRegisterCollab}>
              Finalizar cadastro
            </button>
          </div>
          <img src={registerImg} alt="Register" />
        </form>
      </div>
      <Sidebar />
    </div>
  );
}
