import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiSearch, BiPlus } from "react-icons/bi";
import jsonWebTokenService from "jsonwebtoken";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../CollabSidebar";

import product_default_img from "../../../assets/product_default_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function CollabProducts() {
  const pageTitle = "products";

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  function handleFilterProductsList(e) {
    e.preventDefault();

    const searchString = e.target.value;
    // console.log(searchString);

    setFilteredProducts(
      products.filter((product) => product.nome.includes(searchString))
    );
  }

  async function updateProductsList() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let user;

    if (localStorage.getItem("user") === "entrep") {
      user = "empreendedor";
    } else {
      if (localStorage.getItem("user") === "collab") {
        user = "colaborador";
      }
    }

    const id_empreendimento = jsonWebTokenService.decode(
      localStorage.getItem("token")
    ).id_empreendimento;

    try {
      const response = await api.get(
        `${user}/produtos/${id_empreendimento}`,
        options
      );
      // console.log(response.data);

      if ("data" in response) {
        setProducts(
          response.data.produtos
            .filter((produto) => produto.deleted_at === null)
            .sort((a, b) => (a.id > b.id ? -1 : 1))
        );
        setFilteredProducts(
          response.data.produtos
            .filter((produto) => produto.deleted_at === null)
            .sort((a, b) => (a.id > b.id ? -1 : 1))
        );
      }
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateProductsList();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <div className="search_bar">
            <input
              type="text"
              onChange={(e) => handleFilterProductsList(e)}
              placeholder="Pesquisar..."
            />
            <button id="search_icon">
              <BiSearch />
            </button>
            {/* <button id="slider_icon">
              <BiSlider id="slider_icon" />
            </button> */}
          </div>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="product_list">
          {filteredProducts.map((product) => (
            <div className="product_data" key={product.id}>
              <Link
                id="product_data"
                to={"/collab/products/details/" + product.id}
              >
                <div className="product_data_content">
                  <img
                    src={
                      "fotos_url" in product && product.fotos_url.length > 0
                        ? "https://api.vendarte.company" +
                          product.fotos_url[0].url_img
                        : product_default_img
                    }
                    alt="Foto do produto"
                  />
                  <div className="text">
                    <div>
                      <h2>{product.nome}</h2>
                    </div>
                    <p>
                      {product.comprimento_cm}x{product.largura_cm}x
                      {product.altura_cm}cm
                    </p>
                    <p>{product.peso_grama}g</p>
                    <h3>
                      {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(Number(product.preco) / 100)}
                    </h3>
                  </div>
                  <div className="stars"></div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <Link className="circle_button" to="/collab/products/register">
          <BiPlus />
          <span className="caption">Cadastrar novo produto</span>
        </Link>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
