import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BiPencil,
  BiUser,
  BiIdCard,
  BiPhone,
  BiEnvelope,
  BiMap,
} from "react-icons/bi";

import "./styles.css";

import axios from "axios";
import api from "../../../services/api";

import Sidebar from "../CollabSidebar";

import handleErrorType from "../../../functions/handleErrorType";

import collabIsAuthenticated from "../../../auth/collabIsAuthenticated";

import applyCpfMask from "../../../functions/applyCpfMask";
import applyTelephoneMask from "../../../functions/applyTelephoneMask";
import applyCepMask from "../../../functions/applyCepMask";

import user_upload_img from "../../../assets/user_upload_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function CollabProfile() {
  const pageTitle = "profile";

  const [name, setName] = useState("Nome");
  const [cpf, setCpf] = useState("___________");
  const [telephone, setTelephone] = useState("___________");
  const [email, setEmail] = useState("Email");

  const [cep, setCep] = useState("________");
  const [uf, setUf] = useState("UF");
  const [city, setCity] = useState("Cidade");
  const [district, setDistrict] = useState("Bairro");
  const [number, setNumber] = useState("Nº");
  const [street, setStreet] = useState("Rua");

  const [photoUrl, setPhotoUrl] = useState(null);

  async function handleUploadImage(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      // console.log(reader.result);

      setPhotoUrl(reader.result);
    });
    reader.readAsDataURL(file);

    const form = new FormData();
    form.append("foto_url", file);

    const options = {
      method: "POST",
      url: "",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: form,
    };

    if (localStorage.getItem("user") === "entrep") {
      options.url = `https://api.vendarte.company/api/empreendedor/${localStorage.getItem(
        "id"
      )}/uploadphoto`;
      try {
        const response = await axios.request(options);
        // console.log(response.data);

        alert("Foto atualizada com sucesso!");
      } catch (err) {
        // console.log(err);
        handleErrorType(err);
        updateProfileData();
      }
    } else {
      if (localStorage.getItem("user") === "collab") {
        options.url = `https://api.vendarte.company/api/colaborador/${localStorage.getItem(
          "id"
        )}/uploadphoto`;
        try {
          const response = await axios.request(options);
          // console.log(response.data);

          alert("Foto atualizada com sucesso!");
        } catch (err) {
          // console.log(err);
          handleErrorType(err);
          updateProfileData();
        }
      }
    }
  }

  async function updateProfileData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (localStorage.getItem("user") === "entrep") {
      try {
        const response = await api.get(
          `empreendedor/${localStorage.getItem("id")}`,
          options
        );
        // console.log(response.data);

        setName(response.data.nome);
        setCpf(response.data.cpf);
        setTelephone(response.data.celular);
        setEmail(response.data.email);

        if (response.data.foto_url !== null)
          setPhotoUrl("https://api.vendarte.company" + response.data.foto_url);
      } catch (err) {
        // console.log(err);
        handleErrorType(err);
      }
    } else {
      if (localStorage.getItem("user") === "collab") {
        try {
          const response = await api.get(
            `colaborador/${localStorage.getItem("id")}`,
            options
          );
          // console.log(response.data);

          setName(response.data.nome);
          setCpf(response.data.cpf);
          setTelephone(response.data.celular);
          setEmail(response.data.email);

          setCep(response.data.endereco_cep);
          setUf(response.data.endereco_uf);
          setCity(response.data.endereco_cidade);
          setDistrict(response.data.endereco_bairro);
          setNumber(response.data.endereco_numero);
          setStreet(response.data.endereco_rua);

          if (response.data.foto_url !== null)
            setPhotoUrl(
              "https://api.vendarte.company" + response.data.foto_url
            );
        } catch (err) {
          // console.log(err);
          handleErrorType(err);
        }
      }
    }
  }

  useEffect(() => {
    updateProfileData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Meu perfil</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="profile_details">
          <div className="profile_data_container">
            <div className="profile_data">
              <div className="title">
                <BiUser />
                <h2>Nome</h2>
              </div>
              <p>{name}</p>
              <Link className="data_edit_icon_button" to="/collab/profile/edit">
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiIdCard />
                <h2>CPF</h2>
              </div>
              <p>{applyCpfMask(cpf)}</p>
              <Link className="data_edit_icon_button" to="/collab/profile/edit">
                {/* <BiPencil className="data_edit_icon" /> */}
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiPhone />
                <h2>Telefone</h2>
              </div>
              <p>{applyTelephoneMask(telephone)}</p>
              <Link className="data_edit_icon_button" to="/collab/profile/edit">
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiEnvelope />
                <h2>Email</h2>
              </div>
              <p>{email}</p>
              <Link className="data_edit_icon_button" to="/collab/profile/edit">
                {/* <BiPencil className="data_edit_icon" /> */}
              </Link>
            </div>
            {collabIsAuthenticated() ? (
              <div className="profile_data" style={{ height: "100px" }}>
                <div className="title">
                  <BiMap />
                  <h2>Endereço</h2>
                </div>
                <p>
                  {street}, nº {number}, {district}, {city}-{uf},{" "}
                  {applyCepMask(cep)}
                </p>
                <Link
                  className="data_edit_icon_button"
                  to="/collab/profile/edit"
                >
                  <BiPencil className="data_edit_icon" />
                </Link>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="img_container">
            <img
              src={photoUrl !== null ? photoUrl : user_upload_img}
              alt="Foto de perfil"
            />
            <input type="file" id="img_add" onChange={handleUploadImage} />
            <label className="edit_icon_container" for="img_add">
              <BiPencil />
            </label>
          </div>
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
