import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import ClientPrivateRoute from "./components/private routes/ClientPrivateRoute";
import AdminPrivateRoute from "./components/private routes/AdminPrivateRoute";
import EntrepPrivateRoute from "./components/private routes/EntrepPrivateRoute";
import CollabPrivateRoute from "./components/private routes/CollabPrivateRoute";

import ClientLogin from "./pages/Client/ClientLogin";
import ClientProfile from "./pages/Client/ClientProfile";
import ClientProfileEdit from "./pages/Client/ClientProfileEdit";
import ClientAdressEdit from "./pages/Client/ClientAdressEdit";
import ClientAdressRegister from "./pages/Client/ClientAdressRegister";
import ClientRegister from "./pages/Client/ClientRegister";
import ClientHome from "./pages/Client/ClientHome";
import ClientProduct from "./pages/Client/ClientProduct";
import ClientStoreData from "./pages/Client/ClientStoreData";
import ClientCart from "./pages/Client/ClientCart";
import ClientFinalizeOrder from "./pages/Client/ClientFinalizeOrder";
import ClientOrders from "./pages/Client/ClientOrders";
import ClientOrdersData from "./pages/Client/ClientOrdersData";

import AdminLogin from "./pages/Admin/AdminLogin";
import AdminProfile from "./pages/Admin/AdminProfile";
import AdminProfileEdit from "./pages/Admin/AdminProfileEdit";
import AdminStoreList from "./pages/Admin/AdminStoreList";
import AdminStoreData from "./pages/Admin/AdminStoreData";
import AdminStoreRegister from "./pages/Admin/AdminStoreRegister";

import CollabLogin from "./pages/Collab/CollabLogin";
import CollabProfile from "./pages/Collab/CollabProfile";
import CollabProfileEdit from "./pages/Collab/CollabProfileEdit";
import CollabCollabs from "./pages/Collab/CollabCollabs";
import CollabCollabsRegister from "./pages/Collab/CollabCollabsRegister";
import CollabStoreData from "./pages/Collab/CollabStoreData";
import CollabStoreDataEdit from "./pages/Collab/CollabStoreDataEdit";
import CollabOrders from "./pages/Collab/CollabOrders";
import CollabOrdersData from "./pages/Collab/CollabOrdersData";
import CollabProducts from "./pages/Collab/CollabProducts";
import CollabProductsData from "./pages/Collab/CollabProductsData";
import CollabProductsRegister from "./pages/Collab/CollabProductsRegister";

const freeRoutes = [
  {
    path: "/",
    exact: true,
    component: ClientHome,
  },
  {
    path: "/client",
    exact: true,
    component: ClientHome,
  },
  {
    path: "/client/login",
    exact: false,
    component: ClientLogin,
  },
  {
    path: "/client/register",
    exact: false,
    component: ClientRegister,
  },
  {
    path: "/client/home",
    exact: false,
    component: ClientHome,
  },
  {
    path: "/client/product/:id",
    exact: false,
    component: ClientProduct,
  },
  {
    path: "/client/store/:id",
    exact: false,
    component: ClientStoreData,
  },
  {
    path: "/client/cart",
    exact: false,
    component: ClientCart,
  },
  {
    path: "/collab/login",
    exact: false,
    component: CollabLogin,
  },
  {
    path: "/admin/login",
    exact: false,
    component: AdminLogin,
  },
];

const clientPrivateRoutes = [
  {
    path: "/client/profile",
    exact: true,
    component: ClientProfile,
  },
  {
    path: "/client/profile/edit",
    exact: false,
    component: ClientProfileEdit,
  },
  {
    path: "/client/adress/edit/:id",
    exact: false,
    component: ClientAdressEdit,
  },
  {
    path: "/client/adress/register",
    exact: false,
    component: ClientAdressRegister,
  },
  {
    path: "/client/finalize-order",
    exact: true,
    component: ClientFinalizeOrder,
  },
  {
    path: "/client/orders",
    exact: true,
    component: ClientOrders,
  },
  {
    path: "/client/orders/details/:id",
    exact: true,
    component: ClientOrdersData,
  },
];

const entrepPrivateRoutes = [
  {
    path: "/collab/collabs",
    exact: true,
    component: CollabCollabs,
  },
  {
    path: "/collab/collabs/register",
    exact: true,
    component: CollabCollabsRegister,
  },
  {
    path: "/collab/store/details",
    exact: true,
    component: CollabStoreData,
  },
  {
    path: "/collab/store/details/edit",
    exact: false,
    component: CollabStoreDataEdit,
  },
];

const collabPrivateRoutes = [
  {
    path: "/collab",
    exact: true,
    component: CollabProfile,
  },
  {
    path: "/collab/profile",
    exact: true,
    component: CollabProfile,
  },
  {
    path: "/collab/profile/edit",
    exact: false,
    component: CollabProfileEdit,
  },
  {
    path: "/collab/orders",
    exact: true,
    component: CollabOrders,
  },
  {
    path: "/collab/orders/details/:id",
    exact: true,
    component: CollabOrdersData,
  },
  {
    path: "/collab/products",
    exact: true,
    component: CollabProducts,
  },
  {
    path: "/collab/products/details/:id",
    exact: false,
    component: CollabProductsData,
  },
  {
    path: "/collab/products/register",
    exact: false,
    component: CollabProductsRegister,
  },
];

const adminPrivateRoutes = [
  {
    path: "/admin",
    exact: true,
    component: AdminStoreList,
  },
  {
    path: "/admin/profile",
    exact: true,
    component: AdminProfile,
  },
  {
    path: "/admin/profile/edit",
    exact: false,
    component: AdminProfileEdit,
  },
  {
    path: "/admin/store",
    exact: true,
    component: AdminStoreList,
  },
  {
    path: "/admin/store/details/:id",
    exact: false,
    component: AdminStoreData,
  },
  {
    path: "/admin/store/register",
    exact: false,
    component: AdminStoreRegister,
  },
];

export default function Routes() {
  return (
    <Switch>
      {freeRoutes.map((route) => (
        <Route {...route} key={route.path} />
      ))}

      {clientPrivateRoutes.map((route) => (
        <ClientPrivateRoute {...route} key={route.path} />
      ))}

      {entrepPrivateRoutes.map((route) => (
        <EntrepPrivateRoute {...route} key={route.path} />
      ))}

      {collabPrivateRoutes.map((route) => (
        <CollabPrivateRoute {...route} key={route.path} />
      ))}

      {adminPrivateRoutes.map((route) => (
        <AdminPrivateRoute {...route} key={route.path} />
      ))}
    </Switch>
  );
}
