import React, { useState, useEffect } from "react";
import jsonWebTokenService from "jsonwebtoken";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../CollabSidebar";

import CollabOrder from "../../../components/CollabOrder/CollabOrder";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function CollabOrders() {
  const pageTitle = "orders";

  const [orders, setOrders] = useState([]);

  // let currentOrderListTitle = "";

  // function changeCurrentOrderList(newOrderList) {
  //   let currentOrderList = document.querySelector("#" + currentOrderListTitle);
  //   currentOrderList.classList.toggle("active");

  //   currentOrderList = document.querySelector("#" + newOrderList);
  //   currentOrderList.classList.toggle("active");
  //   currentOrderListTitle = newOrderList;
  // }

  // function setCurrentOrderList() {
  //   try {
  //     let currentOrderList = document.querySelector("#processing");
  //     currentOrderList.classList.toggle("active");
  //     currentOrderListTitle = "processing";
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // async function getStoreId() {
  //   const options = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   };

  //   if (localStorage.getItem("user") === "entrep") {
  //     try {
  //       const response = await api.get(
  //         `empreendedor/${localStorage.getItem("id")}`,
  //         options
  //       );
  //       // console.log(response.data);

  //       updateOrdersList(response.data.id_empreendimento);
  //     } catch (err) {
  //       // console.log(err);
  //       handleErrorType(err);
  //     }
  //   } else {
  //     if (localStorage.getItem("user") === "collab") {
  //       try {
  //         const response = await api.get(
  //           `colaborador/${localStorage.getItem("id")}`,
  //           options
  //         );
  //         // console.log(response.data);

  //         updateOrdersList(response.data.id_empreendimento);
  //       } catch (err) {
  //         // console.log(err);
  //         handleErrorType(err);
  //       }
  //     }
  //   }
  // }

  async function updateOrdersList() {
    const storeId = jsonWebTokenService.decode(
      localStorage.getItem("token")
    ).id_empreendimento;

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (localStorage.getItem("user") === "entrep") {
      try {
        const response = await api.get(
          `empreendedor/pedidos/${storeId}`,
          options
        );
        // console.log(response.data);

        setOrders(
          response.data.sort((a, b) => (a.data_hora > b.data_hora ? -1 : 1))
        );
      } catch (err) {
        // console.log(err);
        handleErrorType(err);
      }
    } else {
      if (localStorage.getItem("user") === "collab") {
        try {
          const response = await api.get(
            `colaborador/pedidos/${storeId}`,
            options
          );
          // console.log(response.data);

          setOrders(
            response.data.sort((a, b) => (a.data_hora > b.data_hora ? -1 : 1))
          );
        } catch (err) {
          // console.log(err);
          handleErrorType(err);
        }
      }
    }
  }

  useEffect(() => {
    // setCurrentOrderList();
    // getStoreId();
    updateOrdersList();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Meus pedidos</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        {/* <div className="top_bar">
          <button
            className="top_bar_button"
            id="processing"
            onClick={() => changeCurrentOrderList("processing")}
          >
            Em processamento
          </button>
          <button
            className="top_bar_button"
            id="transit"
            onClick={() => changeCurrentOrderList("transit")}
          >
            Em trânsito
          </button>
          <button
            className="top_bar_button"
            id="delivered"
            onClick={() => changeCurrentOrderList("delivered")}
          >
            Entregues
          </button>
        </div> */}
        <div className="orders_list">
          {orders.map((order) => (
            <CollabOrder order={order} key={order.id} />
          ))}
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
