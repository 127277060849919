import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import jsonWebTokenService from "jsonwebtoken";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

import loginImg from "../../../assets/login_img.png";

export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  useEffect(() => {
    //
  }, []);

  async function handleLogin(e) {
    e.preventDefault();

    // const email = email;
    //const senha = password;

    const data = {
      email,
      password,
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    // console.log(data);

    try {
      const response = await api.post("auth/adminLogin", data, options);

      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("user");

      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem(
        "id",
        jsonWebTokenService.decode(response.data.access_token).sub
      );
      localStorage.setItem("user", "admin");

      // console.log(localStorage.getItem("token"));
      // console.log(localStorage.getItem("id"));
      // console.log(localStorage.getItem("user"));

      history.push("/admin");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  return (
    <div className="content">
      <div className="form_container">
        <form className="form_content" onSubmit={handleLogin}>
          <h1>Painel do administrador - Login</h1>
          <div className="text_input_container">
            <label className="text_input_title">E-mail:</label>
            <input
              className="text_input_content"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="E-mail"
            />
          </div>
          <div className="text_input_container">
            <label className="text_input_title">Senha:</label>
            <input
              className="text_input_content"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Senha"
            />
          </div>
          <button className="forget_password" type="button" onClick={() => {}}>
            <span>Esqueci minha senha</span>
          </button>
          <button
            className="button1"
            type="submit"
            style={{
              minWidth: "300px",
              width: "100%",
              maxWidth: "600px",
              marginTop: "40px",
            }}
          >
            Entrar
          </button>
          <div
            style={{
              minWidth: "300px",
              width: "100%",
              maxWidth: "600px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Link
              className="button3"
              to="/client/login"
              style={{
                marginTop: "40px",
                minWidth: "200px",
                width: "100%",
                maxWidth: "200px",
                marginRight: "10px",
              }}
            >
              Sou um cliente
            </Link>
            <Link
              className="button3"
              to="/collab"
              style={{
                marginTop: "40px",
                minWidth: "290px",
                width: "100%",
                maxWidth: "300px",
                marginLeft: "10px",
              }}
            >
              Sou empreendedor/colaborador
            </Link>
          </div>
          {/* <Link
            className="button2"
            to="/client/register"
            style={{ marginTop: "40px" }}
          >
            Não tem uma conta? Cadastre-se!
          </Link> */}
        </form>
        <div className="logo_container">
          <Link
            to="/"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logoLight} alt="VendArte" className="logo" />{" "}
          </Link>
          <img
            src={loginImg}
            alt="Ilustração"
            className="illustration"
            style={{ marginTop: "0px" }}
          />
        </div>
      </div>
    </div>
  );
}
