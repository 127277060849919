import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiPlus, BiTrash } from "react-icons/bi";

import "./style.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import applyCpfMask from "../../../functions/applyCpfMask";
import applyTelephoneMask from "../../../functions/applyTelephoneMask";

import Sidebar from "../CollabSidebar";

import user_default_img from "../../../assets/user_default_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function CollabCollabs() {
  const pageTitle = "collabs";

  const [collabs, setCollabs] = useState([]);

  async function handleDeleteCollab(collabId) {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let storeId;

    try {
      const response = await api.get(
        `empreendedor/${localStorage.getItem("id")}`,
        options
      );
      // console.log(response.data);

      storeId = response.data.id_empreendimento;
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }

    try {
      const response = await api.delete(
        `empreendimentos/${storeId}/colaborador/${collabId}`,
        options
      );
      // console.log(response.data);
      alert("Colaborador excluído com sucesso!");

      updateCollabsList();
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateCollabsList() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    let id;

    try {
      const response = await api.get(
        `empreendedor/${localStorage.getItem("id")}`,
        options
      );
      // console.log(response.data);

      id = response.data.id_empreendimento;
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }

    try {
      const response = await api.get(
        `empreendimentos/${id}/colaborador`,
        options
      );
      // console.log(response.data);

      setCollabs(response.data);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateCollabsList();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Colaboradores</h1>
          <img src={logoLight} alt="VendArte" />
        </div>

        <div className="collabs_list">
          {collabs.map((collab) => (
            <div className="collab_data_container" key={collab.id}>
              <div className="collab_data">
                <div className="collab_main">
                  <img
                    src={
                      collab.foto_url !== null
                        ? "https://api.vendarte.company" + collab.foto_url
                        : user_default_img
                    }
                    alt="Foto do colaborador"
                  />
                </div>

                <div className="collab_details">
                  <h2>{collab.nome}</h2>
                  <h3>{"CPF: " + applyCpfMask(collab.cpf)}</h3>
                  <div className="data_group">
                    <p>{applyTelephoneMask(collab.celular) + " - "}</p>
                    <p>{collab.email}</p>
                  </div>
                </div>

                <button
                  className="collab_delete_icon_button"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Tem certeza de que deseja excluir este colaborador do seu empreendimento?"
                      )
                    )
                      handleDeleteCollab(collab.id);
                  }}
                >
                  <BiTrash className="collab_delete_icon" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <Link className="circle_button" to="/collab/collabs/register">
          <BiPlus />
          <span className="caption">Cadastrar novo Colaborador</span>
        </Link>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
