export default function applyDateMask(date) {
  if (date !== "" && date !== null && date !== undefined) {
    return "".concat(
      date.slice(8, 10),
      "/",
      date.slice(5, 7),
      "/",
      date.slice(0, 4),
      " - ",
      date.slice(11)
    );
  } else {
    return date;
  }
}
