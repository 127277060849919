import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BiPencil,
  BiTrash,
  BiUser,
  BiIdCard,
  BiPhone,
  BiEnvelope,
  BiMap,
} from "react-icons/bi";

import "./styles.css";

import axios from "axios";
import api from "../../../services/api";

import Sidebar from "../ClientSidebar";

import handleErrorType from "../../../functions/handleErrorType";

import applyTelephoneMask from "../../../functions/applyTelephoneMask";
import applyCpfMask from "../../../functions/applyCpfMask";
import applyCepMask from "../../../functions/applyCepMask";

import user_upload_img from "../../../assets/user_upload_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientProfile() {
  const pageTitle = "profile";

  const [name, setName] = useState("Nome");
  const [cpf, setCpf] = useState("__________");
  const [telephone, setTelephone] = useState("___________");
  const [email, setEmail] = useState("Email");

  const [addresses, setAddresses] = useState([]);

  const [photoUrl, setPhotoUrl] = useState(null);

  async function handleUploadImage(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      // console.log(reader.result);

      setPhotoUrl(reader.result);
    });
    reader.readAsDataURL(file);

    const form = new FormData();
    form.append("foto_url", file);

    const options = {
      method: "POST",
      url: `https://api.vendarte.company/api/cliente/${localStorage.getItem(
        "id"
      )}/uploadphoto`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: form,
    };

    try {
      const response = await axios.request(options);
      // console.log(response.data);

      alert("Foto atualizada com sucesso!");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateProfileData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `cliente/${localStorage.getItem("id")}`,
        options
      );
      // console.log(response.data);

      setName(response.data.nome);
      setCpf(response.data.cpf);
      setTelephone(response.data.celular);
      setEmail(response.data.email);

      if (response.data.foto_url !== null)
        setPhotoUrl("https://api.vendarte.company" + response.data.foto_url);
      updateAddressesList();
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateAddressesList() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `cliente/${localStorage.getItem("id")}/endereco`,
        options
      );
      // console.log(response.data);

      setAddresses(response.data);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function handleDeleteAdress(id) {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.delete(
        `cliente/${localStorage.getItem("id")}/endereco/${id}`,
        options
      );
      // console.log(response.data);
      alert("Endereço deletado com sucesso!");

      updateAddressesList();
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateProfileData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Meu perfil</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="profile_details">
          <div className="profile_data_container">
            <div className="profile_data">
              <div className="title">
                <BiUser />
                <h2>Nome</h2>
              </div>
              <p>{name}</p>
              <Link className="data_edit_icon_button" to="/client/profile/edit">
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiIdCard />
                <h2>CPF</h2>
              </div>
              <p>{applyCpfMask(cpf)}</p>
              <Link className="data_edit_icon_button" to="/client/profile/edit">
                {/* <BiPencil className="data_edit_icon" /> */}
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiPhone />
                <h2>Telefone</h2>
              </div>
              <p>{applyTelephoneMask(telephone)}</p>
              <Link className="data_edit_icon_button" to="/client/profile/edit">
                <BiPencil className="data_edit_icon" />
              </Link>
            </div>
            <div className="profile_data">
              <div className="title">
                <BiEnvelope />
                <h2>Email</h2>
              </div>
              <p>{email}</p>
              <Link className="data_edit_icon_button" to="/client/profile/edit">
                {/* <BiPencil className="data_edit_icon" /> */}
              </Link>
            </div>
            <h2 className="fields_title">Endereços</h2>
            {addresses.map((adress) => (
              <div
                className="profile_data"
                style={{ height: "auto" }}
                key={adress.id}
              >
                <div className="title">
                  <BiMap />
                  <h2>Endereço</h2>
                </div>
                <p>
                  {adress.logradouro}, nº {adress.numero}, {adress.bairro},{" "}
                  {adress.localidade}-{adress.uf},{" "}
                  {applyCepMask(adress.cep.toString())}
                </p>
                <Link
                  className="adress_edit_icon_button"
                  to={"/client/adress/edit/" + adress.id}
                >
                  <BiPencil className="adress_edit_icon" />
                </Link>
                <button
                  className="adress_delete_icon_button"
                  onClick={() => handleDeleteAdress(adress.id)}
                >
                  <BiTrash className="adress_delete_icon" />
                </button>
              </div>
            ))}
            <Link to="/client/adress/register">
              <div className="button1" style={{ marginTop: "20px" }}>
                Adicionar novo endereço
              </div>
            </Link>
          </div>
          <div className="img_container">
            <img
              src={photoUrl !== null ? photoUrl : user_upload_img}
              alt="Foto de perfil"
            />
            <input type="file" id="img_add" onChange={handleUploadImage} />
            <label className="edit_icon_container" for="img_add">
              <BiPencil />
            </label>
          </div>
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
