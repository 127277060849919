import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BiCalendar, BiUser, BiShoppingBag, BiStats } from "react-icons/bi";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import applyDateMask from "../../../functions/applyDateMask";

import Sidebar from "../ClientSidebar";

import product_default_img from "../../../assets/product_default_img.png";
import coins_img from "../../../assets/coins_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientOrdersData() {
  // const pageTitle = "";

  const [date, setDate] = useState();
  const [totalValue, setTotalValue] = useState();
  const [status, setStatus] = useState();

  const [storeName, setStoreName] = useState("Loja");

  const [orderProducts, setOrderProducts] = useState([]);

  const { id } = useParams();

  async function updateStoreName(storeId) {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get(`empreendimento/${storeId}`, options);
      // console.log(response.data);

      setStoreName(response.data.nome);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateOrderData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(`cliente/pedido/${id}`, options);
      // console.log(response.data);

      setDate(response.data.data_hora);
      setTotalValue(Number(response.data.total) / 100);
      setStatus(response.data.status);

      setOrderProducts(response.data.produtos);

      updateStoreName(response.data.id_empreendimento);
    } catch (err) {
      // console.log(err);
      // handleErrorType(err);
    }
  }

  useEffect(() => {
    updateOrderData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Detalhes do pedido</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="order">
          <div className="order_data">
            <div className="order_data_list">
              <div className="order_data_item">
                <div className="order_data_item_title">
                  <div className="order_data_item_icon">
                    <BiCalendar />
                  </div>
                  <h4>Data do pedido</h4>
                </div>
                <p>{applyDateMask(date)}</p>
              </div>
              {/* <div className="order_data_item">
                <div className="order_data_item_title">
                  <div className="order_data_item_icon">
                    <BiBarcode />
                  </div>
                  <h4>Número do pedido</h4>
                </div>
                <p>00000000.0000</p>
              </div> */}
              <div className="order_data_item">
                <div className="order_data_item_title">
                  <div className="order_data_item_icon">
                    <BiUser />
                  </div>
                  <h4>Nome do empreendimento</h4>
                </div>
                <p>{storeName}</p>
              </div>
              {/* <div className="order_data_item">
                <div className="order_data_item_title">
                  <div className="order_data_item_icon">
                    <BiRename />
                  </div>
                  <h4>Código de rastreio</h4>
                </div>
                <p>XY91081717XY</p>
              </div> */}
            </div>
            <div className="order_value">
              <div className="order_value_title">
                <div className="order_value_title_img">
                  <img src={coins_img} alt="" />
                </div>
                <div className="order_value_title_text">
                  <h3>Valor</h3>
                  <h3>Total</h3>
                </div>
              </div>
              <div className="order_total_value">
                <h1>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(totalValue)}
                </h1>
              </div>
            </div>
          </div>
          <div className="order_data_2">
            <div className="order_products">
              <div className="order_products_title">
                <div className="order_products_icon">
                  <BiShoppingBag />
                </div>
                <h4>Produtos</h4>
              </div>
              {orderProducts.map((product) => (
                <Link
                  className="product"
                  to={"/client/product/" + product.id}
                  key={product.id}
                >
                  <div className="product_quant">
                    x{product.pivot.quantidade}
                  </div>
                  <div className="product_img_data">
                    <div className="product_img">
                      <img
                        src={
                          "fotos_url" in product && product.fotos_url.length > 0
                            ? "https://api.vendarte.company" +
                              product.fotos_url[0].url_img
                            : product_default_img
                        }
                        alt="Foto do produto"
                      />
                    </div>
                    <div className="product_data">
                      <h4>{product.nome}</h4>
                      <h3>
                        {Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(Number(product.preco) / 100)}
                      </h3>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="order_status">
              <div className="order_status_title">
                <div className="order_status_icon">
                  <BiStats />
                </div>
                <h4>Status do pedido</h4>
              </div>
              <div className="order_status_status">
                {status === 0 ? "Pedido em trânsito" : "Pedido entregue"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
    </div>
  );
}
