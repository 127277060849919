export default function applyCnpjMask(cnpj) {
  if (cnpj !== "" && cnpj !== null && cnpj !== undefined) {
    return "".concat(
      cnpj.slice(0, 2),
      ".",
      cnpj.slice(2, 5),
      ".",
      cnpj.slice(5, 8),
      "/",
      cnpj.slice(8, 12),
      "-",
      cnpj.slice(12)
    );
  } else {
    return cnpj;
  }
}
