import React, { useState, useEffect } from "react";
import { BiStore, BiMinus, BiPlus } from "react-icons/bi";

import "./styles.css";

import api from "../../services/api";

import calcCartTotalValue from "../../functions/calcCartTotalValue";
import handleErrorType from "../../functions/handleErrorType";

import product_default_img from "../../assets/product_default_img.png";

export default function CartProduct({ product, parentCallback }) {
  const [id, setId] = useState(product.id);
  const [quantity, setQuantity] = useState(product.quantity);

  const [name, setName] = useState("Produto");
  const [price, setPrice] = useState("");

  const [photoUrl, setPhotoUrl] = useState(null);

  // const [storeId, setStoreId] = useState("");
  const [storeName, setStoreName] = useState("Loja");

  function handleMinusProductQuantity() {
    if (Number(quantity) > 0) {
      setQuantity(Number(quantity) - 1);
    }

    const cartString = localStorage.getItem("cart");
    const cart = [];

    JSON.parse(cartString).map((product) => {
      if (product.id === id) {
        if (product.quantity > 0) product.quantity = product.quantity - 1;
      }
      cart.push(product);
    });

    // console.log(cart);
    localStorage.setItem("cart", JSON.stringify(cart));

    // console.log(price);
    calcCartTotalValue();
    parentCallback(Number(localStorage.getItem("cartTotalValue")));
  }

  function handlePlusProductQuantity() {
    setQuantity(Number(quantity) + 1);

    const cartString = localStorage.getItem("cart");
    const cart = [];

    JSON.parse(cartString).map((product) => {
      if (product.id === id) {
        product.quantity = product.quantity + 1;
      }
      cart.push(product);
    });

    // console.log(cart);
    localStorage.setItem("cart", JSON.stringify(cart));

    // console.log(price);
    calcCartTotalValue();
    parentCallback(Number(localStorage.getItem("cartTotalValue")));
  }

  function handleEditProductQuantity(e) {
    setQuantity(e.target.value);

    const cartString = localStorage.getItem("cart");
    const cart = [];

    JSON.parse(cartString).map((product) => {
      if (product.id === id) {
        product.quantity = Number(e.target.value);
      }
      cart.push(product);
    });

    // console.log(cart);
    localStorage.setItem("cart", JSON.stringify(cart));

    // console.log(price);
    calcCartTotalValue();
    parentCallback(Number(localStorage.getItem("cartTotalValue")));
  }

  async function updateStoreName(storeId) {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get(`empreendimento/${storeId}`, options);
      // console.log(response.data);

      setStoreName(response.data.nome);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateProductData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get(`produto/${id}`, options);
      // console.log(response.data);

      setName(response.data.nome);
      setPrice(Number(response.data.preco) / 100);

      if ("fotos_url" in response.data && response.data.fotos_url.length > 0)
        setPhotoUrl(
          "https://api.vendarte.company" + response.data.fotos_url[0].url_img
        );

      // setStoreId(response.data.id_empreendimento);
      updateStoreName(response.data.id_empreendimento);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateProductData();
    calcCartTotalValue();
    parentCallback(Number(localStorage.getItem("cartTotalValue")));
  }, []);

  return (
    <div>
      <div className="order_products_store_title">
        <BiStore />
        <h4>{storeName}</h4>
      </div>
      <div className="product" style={{ width: "100%" }}>
        <div className="product_quant">
          <button
            className="product_quant_button"
            onClick={handleMinusProductQuantity}
          >
            <BiMinus />
          </button>
          <input
            type="text"
            value={quantity}
            onChange={(e) => handleEditProductQuantity(e)}
            maxLength="3"
          />
          <button
            className="product_quant_button"
            onClick={handlePlusProductQuantity}
          >
            <BiPlus />
          </button>
        </div>
        <div className="product_img_data">
          <div className="product_img">
            <img
              src={photoUrl !== null ? photoUrl : product_default_img}
              alt=""
            />
          </div>
          <div className="product_data">
            <h4>{name}</h4>
            <h3>
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(price)}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
