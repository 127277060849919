import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

import "./styles.css";

import api from "../../../services/api";
import axios from "axios";

import Sidebar from "../AdminSidebar";

import handleErrorType from "../../../functions/handleErrorType";

import registerImg from "../../../assets/register_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function AdminStoreRegister() {
  // const pageTitle = "";

  const [storeName, setStoreName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [storeTelephone, setStoreTelephone] = useState("");
  const [storeEmail, setStoreEmail] = useState("");

  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState("");

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [entrepTelephone, setEntrepTelephone] = useState("");
  const [entrepEmail, setEntrepEmail] = useState("");
  const [entrepPassword, setEntrepPassword] = useState("");

  const history = useHistory();

  async function updateCep(cep) {
    setCep(cep);

    const options = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `validaCep/${cep.replace(/[^\d]/g, "")}`,
        options
      );
      // console.log(response.data);

      setCity(response.data.localidade);
      setUf(response.data.uf);
      setDistrict(response.data.bairro);
      setStreet(response.data.logradouro);
    } catch (err) {
      // console.log(err);
      // handleErrorType(err);
    }
  }

  async function forceDestroyStore(id) {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.delete(
        `administrador/empreendimento/forceDestroy/${id}`,
        options
      );
      // console.log(response.data);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function handleRegisterData(e) {
    e.preventDefault();

    try {
      await handleRegisterCNPJ();
      try {
        const id = await handleRegisterStore();
        // console.log("id: " + id);
        try {
          await handleRegisterEntrepreuner(id);
          alert("Empreendimento cadastrado com sucesso!");

          history.push("/admin/store");
        } catch (err) {
          // console.log(err);

          forceDestroyStore(id);

          handleErrorType(err);
        }
      } catch (err) {
        // console.log(err);
        handleErrorType(err);
      }
    } catch (err) {
      // console.log(err);
      // handleErrorType(err);

      if (err.response.data.msg === "CNPJ já cadastrado") {
        try {
          const id = await handleRegisterStore();
          // console.log("id: " + id);
          try {
            await handleRegisterEntrepreuner(id);
            alert("Empreendimento cadastrado com sucesso!");

            history.push("/admin/store");
          } catch (err) {
            // console.log(err);

            forceDestroyStore(id);

            handleErrorType(err);
          }
        } catch (err) {
          // console.log(err);
          handleErrorType(err);
        }
      }
    }
  }

  async function handleRegisterCNPJ() {
    // e.preventDefault();

    const data = {
      cnpj: cnpj.replace(/[^\d]/g, ""),
    };

    // console.log(data);

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    ////////// COMENTEI O TRYCATCH AQUI PORQUE UNI OS TRÊS CADASTROS EM UMA FUNÇÃO SÓ: handleRegisterData()
    // try {
    const response = await api.post("administrador/cadCnpj", data, options);
    // console.log(response.data);

    // alert("CNPJ cadastrado com sucesso!");
    // } catch (err) {
    //   console.log(err);
    //   handleErrorType(err);
    // }
  }

  async function handleRegisterStore() {
    // e.preventDefault();

    const form = new FormData();
    form.append("nome", storeName);
    form.append("email", storeEmail);
    form.append("conf_email", storeEmail);
    form.append("cnpj", cnpj.replace(/[^\d]/g, ""));
    form.append("celular", storeTelephone.replace(/[^\d]/g, ""));
    form.append("endereco_rua", street);
    form.append("endereco_numero", number);
    form.append("endereco_bairro", district);
    form.append("endereco_cidade", city);
    form.append("endereco_uf", uf);
    form.append("endereco_cep", cep.replace(/[^\d]/g, ""));

    const options = {
      method: "POST",
      url: "https://api.vendarte.company/api/empreendimento",
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: form,
    };

    let id_empreendimento;

    ////////// COMENTEI O TRYCATCH AQUI PORQUE UNI OS TRÊS CADASTROS EM UMA FUNÇÃO SÓ: handleRegisterData()
    // try {
    const response = await axios.request(options);
    // console.log(response.data);

    // alert("Empreendimento cadastrado com sucesso!");

    id_empreendimento = response.data.id_empreendimento;
    // } catch (err) {
    //   // console.error(err);
    //   handleErrorType(err);
    // }

    return id_empreendimento;
  }

  async function handleRegisterEntrepreuner(id) {
    const nome = name;
    // const cpf = cpf;
    const email = entrepEmail;
    const conf_email = entrepEmail;
    const password = entrepPassword;
    const conf_password = entrepPassword;
    const celular = entrepTelephone.replace(/[^\d]/g, "");

    const data = {
      nome,
      cpf: cpf.replace(/[^\d]/g, ""),
      email,
      conf_email,
      password,
      conf_password,
      celular,
    };

    // console.log(data)

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    ////////// COMENTEI O TRYCATCH AQUI PORQUE UNI OS TRÊS CADASTROS EM UMA FUNÇÃO SÓ: handleRegisterData()
    // try {
    const response = await api.post(
      `empreendimento/${id}/empreendedor`,
      data,
      options
    );
    // console.log(response.data);

    // alert("Empreendedor cadastrado com sucesso!");
    // } catch (err) {
    //   console.log(err);
    //   handleErrorType(err);
    // }
  }

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Cadastrar um novo empreendimento</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <form className="register_form">
          <div className="register_fields_container">
            <div className="register_fields">
              <h2>Dados do empreendimento</h2>
              <div className="text_input_container">
                <label className="text_input_title">Nome fantasia:</label>
                <input
                  className="text_input_content"
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                  placeholder="Nome fantasia"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ width: "50%", minWidth: "80px" }}
                >
                  <label className="text_input_title">CNPJ:</label>
                  <InputMask
                    className="text_input_content"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value)}
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{ width: "50%", minWidth: "80px", marginLeft: "25px" }}
                >
                  <label className="text_input_title">Telefone:</label>
                  <InputMask
                    className="text_input_content"
                    value={storeTelephone}
                    onChange={(e) => setStoreTelephone(e.target.value)}
                    placeholder="Telefone"
                    mask="(99) 99999-9999"
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">E-mail:</label>
                <input
                  className="text_input_content"
                  type="email"
                  value={storeEmail}
                  onChange={(e) => setStoreEmail(e.target.value)}
                  placeholder="E-mail"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ width: "80%", minWidth: "80px" }}
                >
                  <label className="text_input_title">CEP:</label>
                  <InputMask
                    className="text_input_content"
                    value={cep}
                    onChange={(e) => updateCep(e.target.value)}
                    placeholder="CEP"
                    mask="99.999-999"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{ width: "20%", minWidth: "80px", marginLeft: "25px" }}
                >
                  <label className="text_input_title">UF:</label>
                  <input
                    className="text_input_content"
                    value={uf}
                    onChange={(e) => setUf(e.target.value)}
                    placeholder="UF"
                    maxLength={2}
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">Cidade:</label>
                <input
                  className="text_input_content"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Cidade"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ width: "70%", minWidth: "80px" }}
                >
                  <label className="text_input_title">Bairro:</label>
                  <input
                    className="text_input_content"
                    value={district}
                    onChange={(e) => setDistrict(e.target.value)}
                    placeholder="Bairro"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{ width: "30%", minWidth: "60px", marginLeft: "25px" }}
                >
                  <label className="text_input_title">Número:</label>
                  <input
                    className="text_input_content"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    placeholder="Nº"
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">Rua:</label>
                <input
                  className="text_input_content"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  placeholder="Rua"
                />
              </div>
            </div>
            <div className="register_fields">
              <h2>Dados do empreendedor</h2>
              <div className="text_input_container">
                <label className="text_input_title">Nome:</label>
                <input
                  className="text_input_content"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nome"
                />
              </div>
              <div className="text_input_line">
                <div
                  className="text_input_container"
                  style={{ width: "50%", minWidth: "80px" }}
                >
                  <label className="text_input_title">CPF:</label>
                  <InputMask
                    className="text_input_content"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    placeholder="CPF"
                    mask="999.999.999-99"
                  />
                </div>
                <div
                  className="text_input_container"
                  style={{ width: "50%", minWidth: "80px", marginLeft: "25px" }}
                >
                  <label className="text_input_title">Telefone:</label>
                  <InputMask
                    className="text_input_content"
                    value={entrepTelephone}
                    onChange={(e) => setEntrepTelephone(e.target.value)}
                    placeholder="Telefone"
                    mask="(99) 99999-9999"
                  />
                </div>
              </div>
              <div className="text_input_container">
                <label className="text_input_title">E-mail:</label>
                <input
                  className="text_input_content"
                  type="email"
                  value={entrepEmail}
                  onChange={(e) => setEntrepEmail(e.target.value)}
                  placeholder="E-mail"
                />
              </div>
              <div className="text_input_container">
                <label className="text_input_title">Senha:</label>
                <input
                  className="text_input_content"
                  type="password"
                  value={entrepPassword}
                  onChange={(e) => setEntrepPassword(e.target.value)}
                  placeholder="Senha"
                />
              </div>
            </div>
            <button className="button1" onClick={handleRegisterData}>
              Finalizar cadastro
            </button>
          </div>
          <img src={registerImg} />
        </form>
      </div>
      <Sidebar />
    </div>
  );
}
