export default function applyTelephoneMask(telephone) {
  return "".concat(
    "(",
    telephone.slice(0, 2),
    ") ",
    telephone.slice(2, 7),
    "-",
    telephone.slice(7)
  );
}
