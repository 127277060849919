import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

import registerImg from "../../../assets/register_img.png";

export default function ClientRegister() {
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [useTerms, setUseTerms] = useState(false);
  const [privacity, setPrivacity] = useState(false);

  const history = useHistory();

  async function handleRegister(e) {
    e.preventDefault();

    setCpf(cpf.replace(/[^\d]/g, ""));
    setTelephone(telephone.replace(/[^\d]/g, ""));

    const nome = name;
    // const cpf = cpf;
    // const email = email;
    const conf_email = confirmEmail;
    // const password = password;
    const conf_password = confirmPassword;
    const celular = telephone;

    const data = {
      nome,
      cpf,
      email,
      conf_email,
      password,
      conf_password,
      celular,
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    // console.log(data);

    try {
      if (useTerms && privacity) {
        const response = await api.post("cliente", data, options);
        alert("Seu cadastro foi realizado com sucesso!");
        // console.log(response.data);

        history.push("/client/login");
      } else {
        alert(
          "Você precisa concordar com nossos Termos de Uso e Política de Privacidade para utilizar nosso sistema."
        );
      }
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  return (
    <div className="content">
      <div className="form_container">
        <form className="form_content" onSubmit={handleRegister}>
          <h1>Cadastre-se</h1>
          <div className="text_input_container">
            <label className="text_input_title">Nome:</label>
            <input
              className="text_input_content"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome"
            />
          </div>
          <div className="text_input_line">
            <div
              className="text_input_container"
              style={{ width: "50%", minWidth: "0px", marginRight: "25px" }}
            >
              <label className="text_input_title">CPF:</label>
              <InputMask
                className="text_input_content"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="CPF"
                mask="999.999.999-99"
              />
            </div>
            <div className="text_input_container" style={{ minWidth: "0px" }}>
              <label className="text_input_title">Telefone:</label>
              <InputMask
                className="text_input_content"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                placeholder="Telefone"
                mask="(99) 99999-9999"
              />
            </div>
          </div>
          <div className="text_input_container">
            <label className="text_input_title">E-mail:</label>
            <input
              className="text_input_content"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="E-mail"
            />
          </div>
          <div className="text_input_container">
            <label className="text_input_title">Confirmar e-mail:</label>
            <input
              className="text_input_content"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              type="email"
              placeholder="Confirmar e-mail"
            />
          </div>
          <div className="text_input_container">
            <label className="text_input_title">Senha:</label>
            <input
              className="text_input_content"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Senha"
            />
          </div>
          <div className="text_input_container">
            <label className="text_input_title">Confirmar senha:</label>
            <input
              className="text_input_content"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="Confirmar senha"
            />
          </div>
          <div className="checkbox_container">
            <input
              type="checkbox"
              name="checkbox_use_terms"
              id="checkbox_use_terms"
              checked={useTerms}
              onChange={(e) => setUseTerms(!useTerms)}
            />
            <label for="checkbox_use_terms">
              Concordo com os{" "}
              <a
                href="https://vendarte.company/docs/Termos_de_Uso.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Termos de Uso
              </a>{" "}
            </label>
          </div>
          <div className="checkbox_container">
            <input
              type="checkbox"
              name="checkbox_privacity"
              id="checkbox_privacity"
              checked={privacity}
              onChange={(e) => setPrivacity(!privacity)}
            />
            <label for="checkbox_privacity">
              Concordo com a{" "}
              <a
                href="https://vendarte.company/docs/Politica_de_Privacidade.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade
              </a>{" "}
            </label>
          </div>
          <button
            className="button1"
            type="submit"
            style={{
              marginTop: "40px",
              minWidth: "300px",
              width: "100%",
              maxWidth: "600px",
            }}
          >
            Finalizar cadastro
          </button>
        </form>
        <div className="logo_container">
          <Link
            to="/"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logoLight} alt="VendArte" className="logo" />{" "}
          </Link>
          <img
            src={registerImg}
            alt="Ilustração"
            className="illustration"
            style={{ marginTop: "0px" }}
          />
        </div>
      </div>
    </div>
  );
}
