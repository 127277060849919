import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import calcCartTotalValue from "../../../functions/calcCartTotalValue";

import applyCepMask from "../../../functions/applyCepMask";

import Sidebar from "../ClientSidebar";

import coins_img from "../../../assets/coins_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientFinalizeOrder() {
  // const pageTitle = "";

  const [addresses, setAddresses] = useState([]);
  const [totalValue, setTotalValue] = useState(0);

  const [selectedAddress, setSelectedAdsress] = useState(0);

  const history = useHistory();

  async function handleFinalizeOrder(e) {
    e.preventDefault();

    let stores = [];

    JSON.parse(localStorage.getItem("cart")).map((product) => {
      stores.push(product.storeId);
    });

    stores = [...new Set(stores)];

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (selectedAddress === 0) {
      alert("Selecione um endereço para a entrega!");
    } else {
      stores.map((store) => {
        const data = {};

        // console.log(data);

        data.produtos = [];

        let storeName;

        JSON.parse(localStorage.getItem("cart"))
          .filter((product) => Number(product.storeId) === Number(store))
          .map((product) => {
            const produto = {
              id: Number(product.id),
              quantidade: product.quantity,
            };
            data.produtos.push(produto);

            storeName = product.storeName;
          });

        try {
          const response = /* await  */ api.post(
            `cliente/pedido/${selectedAddress}`,
            data,
            options
          );
          // console.log(response.data);

          alert(`Pedido enviado com sucesso para a loja ${storeName}!`);
        } catch (err) {
          // console.log(err);
          handleErrorType(err);
          alert(
            `Houve um problema ao enviar seu pedido para a loja ${storeName}.\nAdicione o produto ao carrinho e tente novamente, por favor!`
          );
        }
      });

      localStorage.removeItem("cart");
      localStorage.removeItem("cartTotalValue");
      history.push("/");
    }
  }

  async function updateAddressesList() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `cliente/${localStorage.getItem("id")}/endereco`,
        options
      );
      // console.log(response.data);

      setAddresses(response.data);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateAddressesList();

    calcCartTotalValue();
    setTotalValue(Number(localStorage.getItem("cartTotalValue")));
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Finalizar compra</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="cart_list">
          <div className="order">
            {addresses.length > 0 ? (
              <div className="order_addresses">
                <h3>Selecione o endereço de entrega</h3>
                {addresses.map((address) => (
                  <div className="address" key={address.id}>
                    <input
                      type="radio"
                      name="selected_address"
                      id={address.id}
                      value={address.id}
                      checked={selectedAddress === address.id.toString()}
                      onChange={(e) => setSelectedAdsress(e.target.value)}
                    />
                    <label for={address.id}>
                      {address.logradouro}, nº {address.numero},{" "}
                      {address.bairro}, {address.localidade}-{address.uf},{" "}
                      {applyCepMask(address.cep.toString())}
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <div className="order_addresses">
                <h3>
                  Você não possui endereços cadastrados. Cadastre um na página
                  de perfil.
                </h3>
              </div>
            )}
            <div
              className="order_data"
              style={{ border: "none", alignItems: "flex-start" }}
            >
              <div className="order_value">
                <div className="order_value_title">
                  <div className="order_value_title_img">
                    <img src={coins_img} alt="" />
                  </div>
                  <h4 className="order_total_value_title">Total</h4>
                </div>
                <h3 className="order_total_value">
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(totalValue)}
                </h3>
                <button className="button1" onClick={handleFinalizeOrder}>
                  {" "}
                  Finalizar compra{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <Link className="circle_button" to="">
          <BiCheck />
          <span className="caption">Finalizar compra</span>
        </Link> */}
      </div>
      <Sidebar />
    </div>
  );
}
