import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../ClientSidebar";

import product_default_img from "../../../assets/product_default_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientHome() {
  const pageTitle = "home";

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // function setProductNote() {
  //   products.forEach((product) => {
  //     const note = 3;

  //     for (let i = 1; i <= note; i++) {
  //       let star = document.querySelector("#star" + i);
  //       star.classList.add("colorful");
  //     }
  //   });
  // }

  function handleFilterProductsList(e) {
    e.preventDefault();

    const searchString = e.target.value;
    // console.log(searchString);

    setFilteredProducts(
      products.filter((product) => product.nome.includes(searchString))
    );
  }

  async function updateProductsList() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get("produtos", options);
      // console.log(response.data);

      if ("data" in response && response.data !== "") {
        setProducts(response.data.sort((a, b) => (a.id > b.id ? -1 : 1)));
        setFilteredProducts(
          response.data.sort((a, b) => (a.id > b.id ? -1 : 1))
        );
      }
    } catch (err) {
      console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateProductsList();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <div className="search_bar">
            <input
              type="text"
              onChange={(e) => handleFilterProductsList(e)}
              placeholder="Pesquisar..."
            />
            <button id="search_icon">
              <BiSearch />
            </button>
            {/* <button id="slider_icon">
              <BiSlider id="slider_icon" />
            </button> */}
          </div>
          <img src={logoLight} alt="VendArte" />
        </div>

        <div className="product_list">
          {filteredProducts.map((product) => (
            <div className="product_data" key={product.id}>
              <Link id="product_data" to={"/client/product/" + product.id}>
                <div className="product_data_content">
                  <img
                    src={
                      "fotos_url" in product && product.fotos_url.length > 0
                        ? "https://api.vendarte.company" +
                          product.fotos_url[0].url_img
                        : product_default_img
                    }
                    alt="Foto do produto"
                  />
                  <div
                    className="text"
                    style={{ justifyContent: "space-around" }}
                  >
                    <div>
                      <h2>{product.nome}</h2>
                    </div>
                    <h3>
                      {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(Number(product.preco) / 100)}
                    </h3>
                    {/* <p>Empreendimento</p> */}
                  </div>
                  <div className="stars">
                    {/* <BiStar id="star1" />
                    <BiStar id="star2" />
                    <BiStar id="star3" />
                    <BiStar id="star4" />
                    <BiStar id="star5" /> */}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
