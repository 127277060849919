import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BiMenu,
  BiHome,
  BiUser,
  BiCartAlt,
  BiReceipt,
  BiLogOutCircle,
} from "react-icons/bi";

import "./styles.css";

// import handleErrorType from "../../../functions/handleErrorType";

import clientIsAuthenticated from "../../../auth/clientIsAuthenticated";

// import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientSidebar({ title }) {
  const history = useHistory();

  function activateSideBar() {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("active");
  }

  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("user");
    history.push("/");
    // window.location.reload();
  }

  function setCurrentPage() {
    try {
      let currentPage = document.querySelector("#" + title);

      currentPage.classList.add("current_page");
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    setCurrentPage();
  }, []);

  return (
    <div className="sidebar">
      {/* <div className="menu">
        <button className="menu_btn" onClick={activateSideBar}>
          <div className="sidebar_icon" id="menu_icon">
            <BiMenu />
          </div>
        </button>
      </div> */}
      <ul className="nav_list">
        <li id="menu_link">
          <button className="nav_link" onClick={activateSideBar}>
            <div className="sidebar_icon">
              <BiMenu />
            </div>
            <span className="link_title">Menu</span>
          </button>
          <span className="caption">Expandir</span>
        </li>
        <li>
          <Link className="nav_link" id="home" to="/client/home">
            <div className="sidebar_icon">
              <BiHome />
            </div>
            <span className="link_title">Início</span>
          </Link>
          <span className="caption">Início</span>
        </li>

        {clientIsAuthenticated() === true ? (
          <li>
            <Link className="nav_link" id="profile" to="/client/profile">
              <div className="sidebar_icon">
                <BiUser />
              </div>
              <span className="link_title">Minha conta</span>
            </Link>
            <span className="caption">Minha conta</span>
          </li>
        ) : (
          <li>
            <Link className="nav_link" id="profile" to="/client/login">
              <div className="sidebar_icon">
                <BiUser />
              </div>
              <span className="link_title">Fazer login</span>
            </Link>
            <span className="caption">Fazer login</span>
          </li>
        )}

        <li>
          <Link className="nav_link" id="cart" to="/client/cart">
            <div className="sidebar_icon">
              <BiCartAlt />
            </div>
            <span className="link_title">Carrinho</span>
          </Link>
          <span className="caption">Carrinho</span>
        </li>

        {clientIsAuthenticated() === true ? (
          <li>
            <Link className="nav_link" id="orders" to="/client/orders">
              <div className="sidebar_icon">
                <BiReceipt />
              </div>
              <span className="link_title">Meus pedidos</span>
            </Link>
            <span className="caption">Meus Pedidos</span>
          </li>
        ) : (
          <div />
        )}
      </ul>

      {clientIsAuthenticated() === true ? (
        <div className="logout_button_container">
          <button className="logout_button" onClick={handleLogout}>
            <div className="sidebar_icon">
              <BiLogOutCircle />
            </div>
            <span className="link_title">Sair</span>
          </button>
          <span className="caption">Sair</span>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
