import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import InputMask from "react-input-mask";

import "./styles.css";

import Sidebar from "../ClientSidebar";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import adress_img from "../../../assets/adress_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientAdressEdit() {
  // const pageTitle = "";

  const [cep, setCep] = useState("________");
  const [uf, setUf] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [number, setNumber] = useState("");
  const [street, setStreet] = useState("");

  const { id } = useParams();

  const history = useHistory();

  async function updateCep(cep) {
    setCep(cep);

    const options = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `validaCep/${cep.replace(/[^\d]/g, "")}`,
        options
      );
      // console.log(response.data);

      setCity(response.data.localidade);
      setUf(response.data.uf);
      setDistrict(response.data.bairro);
      setStreet(response.data.logradouro);
    } catch (err) {
      // console.log(err);
      // handleErrorType(err);
    }
  }

  async function handleEditAdressData(e) {
    e.preventDefault();

    // const cep = cep;
    // const uf = uf;
    const localidade = city;
    const bairro = district;
    const numero = number;
    const logradouro = street;

    const data = {
      cep: cep.replace(/[^\d]/g, ""),
      logradouro,
      bairro,
      uf,
      localidade,
      numero,
    };

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.put(
        `cliente/${localStorage.getItem("id")}/endereco/${id}`,
        data,
        options
      );
      // console.log(response.data);
      alert("Endereço atualizado com sucesso!");

      history.push("/client/profile");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateAdressData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(
        `cliente/${localStorage.getItem("id")}/endereco/${id}`,
        options
      );
      // console.log(response.data);

      setCep(response.data.cep);
      setUf(response.data.uf);
      setCity(response.data.localidade);
      setDistrict(response.data.bairro);
      setNumber(response.data.numero);
      setStreet(response.data.logradouro);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateAdressData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Editar endereço</h1>

          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="profile_details">
          <div className="profile_data_container">
            <div className="text_input_line">
              <div
                className="text_input_container"
                style={{ minWidth: "295px" }}
              >
                <label className="text_input_title">CEP:</label>
                <InputMask
                  className="text_input_content"
                  value={cep}
                  onChange={(e) => updateCep(e.target.value)}
                  placeholder="CEP"
                  mask="99.999-999"
                />
              </div>
              <div
                className="text_input_container"
                style={{ width: "80px", minWidth: "80px", marginLeft: "25px" }}
              >
                <label className="text_input_title">UF:</label>
                <input
                  className="text_input_content"
                  value={uf}
                  onChange={(e) => setUf(e.target.value)}
                  placeholder="UF"
                />
              </div>
            </div>
            <div className="text_input_container">
              <label className="text_input_title">Cidade:</label>
              <input
                className="text_input_content"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Cidade"
              />
            </div>
            <div className="text_input_line">
              <div
                className="text_input_container"
                style={{ minWidth: "285px" }}
              >
                <label className="text_input_title">Bairro:</label>
                <input
                  className="text_input_content"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  placeholder="Bairro"
                />
              </div>
              <div
                className="text_input_container"
                style={{ width: "20%", minWidth: "90px", marginLeft: "25px" }}
              >
                <label className="text_input_title">Número:</label>
                <input
                  className="text_input_content"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Nº"
                  style={{ padding: "0 0 0 10px" }}
                />
              </div>
            </div>
            <div className="text_input_container">
              <label className="text_input_title">Rua:</label>
              <input
                className="text_input_content"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="Rua"
              />
            </div>
            <button className="button1" onClick={handleEditAdressData}>
              Finalizar edições
            </button>
          </div>
          <div className="img_container">
            <img src={adress_img} alt="" />
            {/* <button className="edit_icon_container" onClick={() => {}}>
              <BiPencil />
            </button> */}
          </div>
        </div>
      </div>
      <Sidebar />
    </div>
  );
}
