import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiPlus } from "react-icons/bi";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../AdminSidebar";

import store_default_img from "../../../assets/store_default_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function AdminStoreList() {
  const pageTitle = "store";

  const [stores, setStores] = useState([]);

  async function updateStoreList() {
    const options = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get("administrador/empreendimento", options);
      // console.log(response.data);

      if ("data" in response && response.data !== "") setStores(response.data);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    updateStoreList();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Empreendimentos</h1>
          <img src={logoLight} alt="VendArte" />
        </div>

        <div className="store_list">
          {stores.map((store) => (
            <div className="store_data" key={store.id}>
              <Link id="store_data" to={"/admin/store/details/" + store.id}>
                <div className="store_data_content">
                  <img
                    src={
                      store.logo_url !== null
                        ? "https://api.vendarte.company" + store.logo_url
                        : store_default_img
                    }
                    alt="Logotipo do empreendimento"
                  />
                  <div>
                    <h2>{store.nome}</h2>
                    <div>
                      <p>{store.endereco_cidade + "-" + store.endereco_uf}</p>
                      <p>{store.endereco_rua + ", " + store.endereco_numero}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <Link className="circle_button" to="/admin/store/register">
          <BiPlus />
          <span className="caption">Cadastrar novo empreendimento</span>
        </Link>
      </div>
      <Sidebar title={pageTitle} />
    </div>
  );
}
