import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BiMenu,
  BiStore,
  BiGroup,
  BiUser,
  BiBox,
  BiReceipt,
  BiLogOutCircle,
} from "react-icons/bi";

import "./styles.css";

// import handleErrorType from "../../../functions/handleErrorType";

import entrepIsAuthenticated from "../../../auth/entrepIsAuthenticated";
import collabIsAuthenticated from "../../../auth/collabIsAuthenticated";

// import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function Sidebar({ title }) {
  const history = useHistory();

  function activateSideBar() {
    let sidebar = document.querySelector(".collab_sidebar");
    sidebar.classList.toggle("active");
  }

  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("user");
    history.push("/collab");
    // window.location.reload();
  }

  function setCurrentPage() {
    try {
      let currentPage = document.querySelector("#" + title);

      currentPage.classList.add("current_page");
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    setCurrentPage();
  }, []);

  return (
    <div className="collab_sidebar">
      {/* <div className="menu">
        <button className="menu_btn" onClick={activateSideBar}>
          <div className="sidebar_icon" id="menu_icon">
            <BiMenu />
          </div>
        </button>
      </div> */}

      <ul className="nav_list">
        <li id="menu_link">
          <button className="nav_link" onClick={activateSideBar}>
            <div className="sidebar_icon">
              <BiMenu />
            </div>
            <span className="link_title">Menu</span>
          </button>
          <span className="caption">Expandir</span>
        </li>
        <li>
          <Link className="nav_link" id="profile" to="/collab/profile">
            <div className="sidebar_icon">
              <BiUser />
            </div>
            <span className="link_title">Minha conta</span>
          </Link>
          <span className="caption">Minha Conta</span>
        </li>
        {entrepIsAuthenticated() ? (
          <li>
            <Link className="nav_link" id="collabs" to="/collab/collabs">
              <div className="sidebar_icon">
                <BiGroup />
              </div>
              <span className="link_title">Colaboradores</span>
            </Link>
            <span className="caption">Colaboradores</span>
          </li>
        ) : (
          <div />
        )}
        <li>
          <Link className="nav_link" id="products" to="/collab/products">
            <div className="sidebar_icon">
              <BiBox />
            </div>
            <span className="link_title">Produtos</span>
          </Link>
          <span className="caption">Produtos</span>
        </li>
        <li>
          <Link className="nav_link" id="orders" to="/collab/orders">
            <div className="sidebar_icon">
              <BiReceipt />
            </div>
            <span className="link_title">Pedidos</span>
          </Link>
          <span className="caption">Pedidos</span>
        </li>
        {entrepIsAuthenticated() ? (
          <li>
            <Link className="nav_link" id="store" to="/collab/store/details">
              <div className="sidebar_icon">
                <BiStore />
              </div>
              <span className="link_title">Empreendimento</span>
            </Link>
            <span className="caption">Empreendimento</span>
          </li>
        ) : (
          <div />
        )}
      </ul>
      {entrepIsAuthenticated || collabIsAuthenticated() ? (
        <div className="logout_button_container">
          <button className="logout_button" onClick={handleLogout}>
            <div className="sidebar_icon">
              <BiLogOutCircle />
            </div>
            <span className="link_title">Sair</span>
          </button>
          <span className="caption">Sair</span>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
