export default function () {
  const cartString = localStorage.getItem("cart");
  const cart = JSON.parse(cartString);

  if (cart !== null && cart.length > 0) {
    const totalValue = cart.reduce((accumulator, product) => {
      return accumulator + product.quantity * product.price;
    }, 0);

    localStorage.setItem("cartTotalValue", totalValue);

    // console.log(cart);
    // console.log(totalValue);
  }
}
