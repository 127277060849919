import React from "react";
import { Route, Redirect } from "react-router-dom";

import entrepIsAuthenticated from "../../auth/entrepIsAuthenticated";
import collabIsAuthenticated from "../../auth/collabIsAuthenticated";

export default function CollabPrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        entrepIsAuthenticated() || collabIsAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/collab/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
