import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import IntlCurrencyInput from "react-intl-currency-input";
import { BiTrash, BiPlus } from "react-icons/bi";

import "./styles.css";

import api from "../../../services/api";
import axios from "axios";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../CollabSidebar";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function CollabProductsData() {
  // const pageTitle = "";

  const { id } = useParams();

  const [name, setName] = useState("");
  const [inventory, setInventory] = useState("");
  const [price, setPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [description, setDescription] = useState("");

  const [imgs, setImgs] = useState([null, null, null, null, null]);
  const [imgUrls, setImgUrls] = useState([null, null, null, null, null]);

  const history = useHistory();

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  async function handleUploadImages(e) {
    e.preventDefault();

    let count = 0;
    const form = new FormData();
    imgs.map((img) => {
      if (typeof img === "object" && img !== null) {
        form.append("fotos_url[" + count + "]", img);
        count++;
        // console.log(img);
      }
    });

    let user;

    if (localStorage.getItem("user") === "entrep") {
      user = "empreendedor";
    } else {
      if (localStorage.getItem("user") === "collab") {
        user = "colaborador";
      }
    }

    const options = {
      method: "POST",
      url: `https://api.vendarte.company/api/${user}/uploadPhoto/produto/${id}`,
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: form,
    };

    if (count > 0) {
      try {
        const response = await axios.request(options);
        // console.log(response.data);
        alert("Fotos atualizadas com sucesso!");
      } catch (err) {
        // console.log(err);
        handleErrorType(err);
      }
    }

    handleEditProductData();
  }

  function handleSelectImage(e) {
    const imgArray = [];
    const imgUrlArray = [];
    const file = e.target.files[0];
    let imgAdded = false;
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      // console.log(reader.result);

      imgUrls.map((img) => {
        if (imgAdded || img !== null) {
          imgUrlArray.push(img);
        } else {
          if (!imgAdded && img === null) {
            imgUrlArray.push(reader.result);
            imgAdded = true;
          }
        }
      });
      imgAdded = false;

      // console.log(imgUrlArray);
      setImgUrls(imgUrlArray);
    });
    reader.readAsDataURL(file);

    imgs.map((img) => {
      if (imgAdded || img !== null) {
        imgArray.push(img);
      } else {
        if (!imgAdded && img === null) {
          imgArray.push(file);
          imgAdded = true;
        }
      }
    });
    imgAdded = false;

    // console.log(imgArray);
    setImgs(imgArray);
  }

  async function handleDeleteImage(ind) {
    const imgArray = [...imgs];
    const imgUrlArray = [...imgUrls];

    if (typeof imgArray[ind] === "object") {
      imgArray.splice(ind, 1);
      imgArray.push(null);

      imgUrlArray.splice(ind, 1);
      imgUrlArray.push(null);

      // console.log(imgArray);
      // console.log(imgUrlArray);

      setImgs(imgArray);
      setImgUrls(imgUrlArray);
    } else {
      if (typeof imgArray[ind] === "number") {
        let user;

        if (localStorage.getItem("user") === "entrep") {
          user = "empreendedor";
        } else {
          if (localStorage.getItem("user") === "collab") {
            user = "colaborador";
          }
        }

        const options = {
          headers: {
            // "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };

        try {
          const response = await api.delete(
            `${user}/produto/img/${imgArray[ind]}`,
            options
          );
          // console.log(response.data);
          alert("Foto deletada com sucesso!");

          imgArray.splice(ind, 1);
          imgArray.push(null);

          imgUrlArray.splice(ind, 1);
          imgUrlArray.push(null);

          // console.log(imgArray);
          // console.log(imgUrlArray);

          setImgs(imgArray);
          setImgUrls(imgUrlArray);
        } catch (err) {
          // console.log(err);
          handleErrorType(err);
        }
      }
    }
  }

  async function handleDeleteProduct() {
    let user;

    if (localStorage.getItem("user") === "entrep") {
      user = "empreendedor";
    } else {
      if (localStorage.getItem("user") === "collab") {
        user = "colaborador";
      }
    }

    const options = {
      headers: {
        // "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.delete(`${user}/produto/${id}`, options);
      // console.log(response.data);
      alert("Produto deletado com sucesso!");

      history.push("/collab/products");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function handleEditProductData() {
    const nome = name;
    const descricao = description;
    const estoque = inventory;
    const peso_grama = weight;
    const largura_cm = width;
    const altura_cm = height;
    const comprimento_cm = length;
    const unidade_medida = unit;
    const preco = Number(price.toString().replace(/[^\d]/g, ""));

    const data = {
      nome,
      descricao,
      estoque,
      peso_grama,
      largura_cm,
      altura_cm,
      comprimento_cm,
      unidade_medida,
      preco,
    };

    let user;

    if (localStorage.getItem("user") === "entrep") {
      user = "empreendedor";
    } else {
      if (localStorage.getItem("user") === "collab") {
        user = "colaborador";
      }
    }

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    // console.log(data);

    try {
      const response = await api.put(`${user}/produto/${id}`, data, options);
      // console.log(response.data);
      alert("Produto atualizado com sucesso!");

      history.push("/collab/products");
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateProductData() {
    let user;

    if (localStorage.getItem("user") === "entrep") {
      user = "empreendedor";
    } else {
      if (localStorage.getItem("user") === "collab") {
        user = "colaborador";
      }
    }

    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    try {
      const response = await api.get(`${user}/produto/${id}`, options);
      // console.log(response.data);

      setName(response.data.nome);
      setInventory(response.data.estoque);
      setPrice(response.data.preco.toString());
      setUnit(response.data.unidade_medida);
      setWeight(response.data.peso_grama);
      setLength(response.data.comprimento_cm);
      setWidth(response.data.largura_cm);
      setHeight(response.data.altura_cm);
      setDescription(response.data.descricao);

      if ("fotos_url" in response.data && response.data.fotos_url.length > 0) {
        const imgArray = [...imgs];
        const imgUrlArray = [...imgUrls];
        // console.log(imgArray);
        // console.log(imgUrlArray);

        response.data.fotos_url.map((img, ind) => {
          imgArray[ind] = img.id;
          imgUrlArray[ind] = "https://api.vendarte.company" + img.url_img;
        });

        // console.log(imgArray);
        // console.log(imgUrlArray);

        setImgs(imgArray);
        setImgUrls(imgUrlArray);
      }
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    // console.log(id);
    updateProductData();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>Dados do produto</h1>
          <img src={logoLight} alt="VendArte" />
        </div>
        <div className="product_imgs">
          {imgUrls.map((img, ind) => (
            <div className="product_img" key={ind}>
              {img !== null ? (
                <div>
                  <img src={img} alt="Foto do produto" />
                  <button
                    className="trash_icon"
                    onClick={() => handleDeleteImage(ind)}
                  >
                    <BiTrash />
                  </button>
                </div>
              ) : (
                <div>
                  <input
                    type="file"
                    id="img_add"
                    onChange={handleSelectImage}
                  />
                  <label className="img_add" for="img_add">
                    <div className="img_add_icon">
                      <BiPlus />
                    </div>
                  </label>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="product_details">
          <div className="product_more_details">
            <div className="text_input_container">
              <label className="text_input_title">Nome:</label>
              <input
                className="text_input_content"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome"
              />
            </div>
            <div className="text_input_line">
              <div
                className="text_input_container"
                style={{ width: "100%", minWidth: "40px" }}
              >
                <label className="text_input_title">Estoque:</label>
                <input
                  className="text_input_content"
                  type="text"
                  value={inventory}
                  onChange={(e) => setInventory(e.target.value)}
                  placeholder="Estoque"
                />
              </div>
              <div
                className="text_input_container"
                style={{ width: "100%", minWidth: "40px" }}
              >
                <label className="text_input_title">Preço:</label>
                <IntlCurrencyInput
                  className="text_input_content"
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  // placeholder="Preço"
                  currency="BRL"
                  config={currencyConfig}
                />
              </div>
            </div>
            <div className="text_input_line">
              <div
                className="text_input_container"
                style={{ width: "100%", minWidth: "50px" }}
              >
                <label className="text_input_title">Unidade:</label>
                <input
                  className="text_input_content"
                  type="text"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  placeholder="Unidade"
                />
              </div>
              <div
                className="text_input_container"
                style={{ width: "100%", minWidth: "50px" }}
              >
                <label className="text_input_title">Peso (g):</label>
                <input
                  className="text_input_content"
                  type="text"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  placeholder="Peso"
                />
              </div>
            </div>
            <div className="text_input_line">
              <div
                className="text_input_container"
                style={{
                  width: "100%",
                  minWidth: "150px",
                }}
              >
                <label className="text_input_title">Comprimento (cm):</label>
                <input
                  className="text_input_content"
                  type="text"
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                  placeholder="Comprimento"
                />
              </div>
              <div
                className="text_input_container"
                style={{
                  width: "100%",
                  minWidth: "110px",
                }}
              >
                <label className="text_input_title">Largura (cm):</label>
                <input
                  className="text_input_content"
                  type="text"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  placeholder="Largura"
                />
              </div>
              <div
                className="text_input_container"
                style={{
                  width: "100%",
                  minWidth: "0px",
                }}
              >
                <label className="text_input_title">Altura (cm):</label>
                <input
                  className="text_input_content"
                  type="text"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  placeholder="Altura"
                />
              </div>
            </div>
          </div>
          <div className="product_description">
            <div className="text_input_container" style={{ height: "100%" }}>
              <label className="text_input_title">Descrição:</label>
              <textarea
                className="text_input_content"
                style={{
                  height: "100%",
                  padding: "8px 10px 8px 10px",
                  resize: "none",
                }}
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Descrição"
              />
            </div>
          </div>
        </div>
        <div className="product_buttons">
          <button className="button1" onClick={handleUploadImages}>
            Salvar produto
          </button>
          <button
            className="button2"
            onClick={() => {
              if (
                window.confirm(
                  "Tem certeza de que deseja excluir este produto?"
                )
              )
                handleDeleteProduct();
            }}
          >
            Excluir produto
          </button>
        </div>
      </div>
      <Sidebar />
    </div>
  );
}
