import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { BiMenu, BiBuildings, BiUser, BiLogOutCircle } from "react-icons/bi";

import "./styles.css";

// import handleErrorType from "../../../functions/handleErrorType";

import adminIsAuthenticated from "../../../auth/adminIsAuthenticated";

// import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function Sidebar({ title }) {
  const history = useHistory();

  function activateSideBar() {
    let sidebar = document.querySelector(".admin_sidebar");
    sidebar.classList.toggle("active");
  }

  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("user");
    history.push("/admin");
    // window.location.reload();
  }

  function setCurrentPage() {
    try {
      let currentPage = document.querySelector("#" + title);

      currentPage.classList.add("current_page");
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    setCurrentPage();
  }, []);

  return (
    <div className="admin_sidebar">
      {/* <div className="menu">
        <button className="menu_btn" onClick={activateSideBar}>
          <div className="sidebar_icon" id="menu_icon">
            <BiMenu />
          </div>
        </button>
      </div> */}

      <ul className="nav_list">
        <li id="menu_link">
          <button className="nav_link" onClick={activateSideBar}>
            <div className="sidebar_icon">
              <BiMenu />
            </div>
            <span className="link_title">Menu</span>
          </button>
          <span className="caption">Expandir</span>
        </li>
        <li>
          <Link className="nav_link" id="store" to="/admin/store">
            <div className="sidebar_icon">
              <BiBuildings />
            </div>
            <span className="link_title">Empreendimentos</span>
          </Link>
          <span className="caption">Empreendimentos</span>
        </li>
        <li>
          <Link className="nav_link" id="profile" to="/admin/profile">
            <div className="sidebar_icon">
              <BiUser />
            </div>
            <span className="link_title">Minha conta</span>
          </Link>
          <span className="caption">Minha Conta</span>
        </li>
      </ul>
      {adminIsAuthenticated() ? (
        <div className="logout_button_container">
          <button className="logout_button" onClick={handleLogout}>
            <div className="sidebar_icon">
              <BiLogOutCircle />
            </div>
            <span className="link_title">Sair</span>
          </button>
          <span className="caption">Sair</span>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
