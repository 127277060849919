export default function handleErrorType(err) {
  if ("response" in err) {
    if (
      err.response.status === 401 &&
      err.response.data.msg === "Token expirado. Faça login novamente."
    ) {
      alert("Sua sessão expirou, faça o login novamente.");

      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("user");

      window.location.replace("/");
    } else if ("errors" in err.response.data) {
      let errorString = "Erros encontrados: \n";
      for (var key in err.response.data.errors) {
        err.response.data.errors[key].map((error) => {
          errorString += "\n  -  " + error;
        });
      }
      alert(errorString);
    } else if ("error" in err.response.data) alert(err.response.data.error);
    else if ("msg" in err.response.data) alert(err.response.data.msg);
  }
}
