import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { Carousel } from "3d-react-carousal";

import "./styles.css";

import api from "../../../services/api";

import handleErrorType from "../../../functions/handleErrorType";

import Sidebar from "../ClientSidebar";

import product_default_img from "../../../assets/product_default_img.png";

import logoLight from "../../../assets/logo-light-bg.svg";
// import logoRed from "../../../assets/logo-red-bg.svg";
// import logoBlue from "../../../assets/logo-blue-bg.svg";

export default function ClientProduct() {
  // const pageTitle = "";

  const [name, setName] = useState("");
  // const [inventory, setInventory] = useState("");
  const [price, setPrice] = useState("");
  // const [unit, setUnit] = useState("");
  // const [weight, setWeight] = useState("");
  // const [length, setLength] = useState("");
  // const [width, setWidth] = useState("");
  // const [height, setHeight] = useState("");
  const [description, setDescription] = useState("");

  const [storeId, setStoreId] = useState("");
  const [storeName, setStoreName] = useState("");

  const { id } = useParams();

  var Carousel = require("3d-react-carousal").Carousel;

  const defafultImgs = [
    <img src={product_default_img} alt="Produto sem foto" />,
  ];
  const [imgs, setImgs] = useState(defafultImgs);

  // function setProductNote() {
  //   const note = 4;

  //   for (let i = 1; i <= note; i++) {
  //     let star = document.querySelector("#star" + i);
  //     star.classList.add("product_stars_colorful");
  //   }
  // }

  function handleAddProductToTheCart() {
    const cartString = localStorage.getItem("cart");
    const cart = [];

    if (cartString === null) {
      cart.push({
        id,
        quantity: 1,
        price,
        storeId,
        storeName,
      });

      // console.log(cart);
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      let aux = 0;

      JSON.parse(cartString).map((product) => {
        if (product.id === id) {
          product.quantity = product.quantity + 1;
          aux++;
        }
        cart.push(product);
      });

      if (aux === 0) {
        cart.push({
          id,
          quantity: 1,
          price,
          storeId,
          storeName,
        });
      }

      // console.log(cart);
      localStorage.setItem("cart", JSON.stringify(cart));
    }

    alert("Produto adicionado ao carrinho!");
  }

  async function updateStoreName(storeId) {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get(`empreendimento/${storeId}`, options);
      // console.log(response.data);

      setStoreName(response.data.nome);
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  async function updateProductData() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await api.get(`produto/${id}`, options);
      // console.log(response.data);

      setName(response.data.nome);
      // setInventory(response.data.estoque);
      setPrice(Number(response.data.preco) / 100);
      // setUnit(response.data.unidade_medida);
      // setWeight(response.data.peso_grama);
      // setLength(response.data.comprimento_cm);
      // setWidth(response.data.largura_cm);
      // setHeight(response.data.altura_cm);
      setDescription(response.data.descricao);

      setStoreId(response.data.id_empreendimento);
      updateStoreName(response.data.id_empreendimento);

      if ("fotos_url" in response.data && response.data.fotos_url.length > 0) {
        setImgs(
          response.data.fotos_url.map((img) => (
            <img
              src={"https://api.vendarte.company" + img.url_img}
              alt="Foto do produto"
            />
          ))
        );
      }
    } catch (err) {
      // console.log(err);
      handleErrorType(err);
    }
  }

  useEffect(() => {
    // console.log(id);
    updateProductData();
    // setProductNote();
  }, []);

  return (
    <div>
      <div className="page_content">
        <div className="head">
          <h1>{name}</h1>
          <img src={logoLight} alt="VendArte" />
        </div>

        <div className="product">
          <div className="product_data">
            <div className="product_main_data">
              {/* <div className="product_stars">
                <FaStar id="star1" />
                <FaStar id="star2" />
                <FaStar id="star3" />
                <FaStar id="star4" />
                <FaStar id="star5" />
              </div> */}
              <Link className="store_link" to={"/client/store/" + storeId}>
                <h4>{storeName}</h4>
              </Link>
              <h3>
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(price)}
              </h3>
              <button className="button1" onClick={handleAddProductToTheCart}>
                Adicionar ao carrinho
              </button>
            </div>
            <div className="product_img">
              <Carousel
                slides={imgs}
                /* arrows={false} autoplay={true} interval={1000} */
              />
            </div>
          </div>
          <div className="product_description">
            <h4>Descrição do produto</h4>
            <p>{description}</p>
          </div>
        </div>
      </div>
      <Sidebar />
    </div>
  );
}
